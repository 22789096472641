import { useState } from "react";
import STUtils from "../../assets/styles/utility.module.scss";
import { routes } from "../../router/Router";
import { getSubdomain } from "../../utils/getSubdomain";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import PointRequestApplyCard from "../organisms/PointRequestApplyCard";
import PointRequestCard from "../organisms/PointRequestCard";
import BaseHead from "../templates/BaseHead";

const PointRequest: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const [isApply, setIsApply] = useState(false);

  return (
    <BaseHead
      title={routes.pointRequest.title}
      login={true}
      subdomain={subdomain}
      hasNavigationBar={true}
      hasBackButton={true}
    >
      <div className={STUtils.container_large}>
        <>
          {!isApply ? (
            <PointRequestCard setIsApply={setIsApply} />
          ) : (
            <PointRequestApplyCard />
          )}
          <div>
            <BottomNavigationBar />
          </div>
        </>
      </div>
    </BaseHead>
  );
};
export default PointRequest;
