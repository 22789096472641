import client from "../../api/client";
import TApiResult from "../../types/api/TApiResult";
import { AxiosError } from "axios";
import getErrorProperty from "./config/getErrorProperty";
import { TSurveyFormsResponce } from "../../api/survey_forms/_id@string";

export type TApiSurveyFormResult = {
  result?: TSurveyFormsResponce;
};

export const fetchSurveyForms = async (
  id: string
): Promise<TApiResult & TApiSurveyFormResult> => {
  return client()
    .survey_forms._id(id)
    .get()
    .then((res) => {
      return {
        isSuccess: true,
        status: 200,
        message: "",
        result: res.body,
      };
    })
    .catch((e: AxiosError) => {
      console.log(e);
      const { status, message } = getErrorProperty(e, "fetchSurveyForm");
      return {
        isSuccess: false,
        status: status,
        message: message,
      };
    });
};
