import STUtils from "../../assets/styles/utility.module.scss";
import ErrorCard from "../organisms/ErrorCard";
import BaseHeadNoindex from "../templates/BaseHeadNoindex";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { fetchChatConfig } from "../../utils/api/fetchChatConfig";
import { CircularProgress } from "@material-ui/core";

const ChatOff: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchChatConfig().then((res: any) => {
      if (!res.isSuccess || !res.result) return;
      if (res.result[0].is_active) {
        return history.push(routes.chat.path);
      }
      setIsLoading(false);
    })
  }, [])

  return (
    <BaseHeadNoindex
      title={routes.error.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
      showFooter={false}
    >
      <LoginBackground />
      <div className={STUtils.container_large}>
        {isLoading ? <CircularProgress /> : <ErrorCard isChatOff={true} />}
      </div>
      <div >
        <BottomNavigationBar />
      </div>
    </BaseHeadNoindex>
  );
};

export default ChatOff;
