import { Link } from "react-router-dom";
import STNaviMenu from "../../assets/styles/molecules/naviMenu.module.scss";
import PAGE_CONFIG from "../../config/page";
import { NaviItem } from "../../types/naviItem";
import SvgArrowRightMenuHeader from "../atoms/icons/ArrowRightMenuHeader";

type Props = {
  className: string;
  naviItems: NaviItem[];
  showArrowIcon: boolean | undefined;
};

const NaviMenu: React.FC<Props> = (props: Props) => {  
  const urlPattern = /^http.+/;
  return (
    <nav role="navigation" className={props.className}>
      <ol className={STNaviMenu.naviMenu_new}>
        {props.naviItems.map((naviItem) => (
          <li key={naviItem.id}>
            {!urlPattern.test(naviItem.url) ? (
              <Link to={naviItem.url}>
                <div className={STNaviMenu.naviMenu_link_new}>
                  <div className={STNaviMenu.naviItem}>
                    {naviItem.icon ? (
                      <div
                        className={`${STNaviMenu.naviMenu_icon} ${PAGE_CONFIG.STATIC_CLASSES.SVG_ICON}`}
                      >
                        <img src={naviItem.icon} alt={naviItem.name}/>
                      </div>
                    ) : (
                      <></>
                    )}
                    <p className={STNaviMenu.naviMenu_link_text}>
                      {naviItem.name}
                    </p>
                  </div>
                  {props.showArrowIcon &&<SvgArrowRightMenuHeader/>}
                </div>
              </Link>
            ):(
              <a href={naviItem.url}>
                <div className={STNaviMenu.naviMenu_link_new}>
                  <div className={STNaviMenu.naviItem}>
                  {naviItem.icon ? (
                    <div
                      className={`${STNaviMenu.naviMenu_icon} ${PAGE_CONFIG.STATIC_CLASSES.SVG_ICON}`}
                    >
                      <img src={naviItem.icon} alt={naviItem.name} />
                    </div>
                    ) : (
                      <></>
                    )}
                    <p className={STNaviMenu.naviMenu_link_text}>
                      {naviItem.name}
                    </p>
                  </div>
                  {props.showArrowIcon && <SvgArrowRightMenuHeader/>}
                </div>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default NaviMenu;