import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { AXIOS_INSTANCE } from "../../api/client";

export type TSurveyFormAnswerResponce = {
  status_code: number;
  values: string;
};

export const postSurveyForms = async (
  id: string,
  body: any
): Promise<TSurveyFormAnswerResponce> => {
  const request = {
    url: `/survey_forms/${id}/survey_answers`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" } as AxiosRequestHeaders,
    data: body,
  };
  return AXIOS_INSTANCE(request as AxiosRequestConfig<any>).then(
    ({ data }) => data
  );
};
