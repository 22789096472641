import STUtils from "../../assets/styles/utility.module.scss";
import MemberEntryForm from "../organisms/MemberEntryForm";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import { useEffect, useState } from "react";
import {
  fetchMemberRegister,
  TApiMemberRegisterResult,
} from "../../utils/api/fetchMemberRegister";
import TApiResult from "../../types/api/TApiResult";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "../../router/Router";
import { Customer } from "../../api/member/register/_id@string/_datetime_hash@string";
import { fetchSite, TApiSiteResult } from "../../utils/api/fetchSite";
import { useAuth } from "../../hooks/useAuth";
import BaseHeadNoindex from "../templates/BaseHeadNoindex";

const MemberEntry = (): JSX.Element => {
  const urlParams = useParams<{ id: string; datetimeHash: string }>();

  const subdomain = getSubdomain(window.location.hostname);

  const [customer, setCustomer] = useState<Customer>({});
  const [isApplied, setIsApplied] = useState(false);
  const [signinData, setSigninData] = useState({ email: null, password: null });
  const history = useHistory();
  const { login } = useAuth();

  useEffect(() => {
    if (urlParams?.id && urlParams?.datetimeHash) {
      fetchMemberRegister(urlParams.id, urlParams.datetimeHash).then(
        (res: TApiResult & TApiMemberRegisterResult) => {
          if (!res.isSuccess || !res.result)
            return history.push(routes.error.path);
          setCustomer(res.result.customer);
        }
      );
    }
  }, []);
  useEffect(() => {
    if (isApplied && signinData.email && signinData.password) {
      const signin = async (email: string, password: string, save: string) => {
        fetchSite().then((res: TApiResult & TApiSiteResult) => {
          if (!res.isSuccess || !res.result)
            return history.push(routes.error.path);
          login(email, password, save === "1", res.result.gcp_id).then(
            (isSucceeded) => {
              if (isSucceeded) {
                return history.push("/");
              } else return history.push(routes.error.path);
            }
          );
        });
      };
      signin(signinData.email, signinData.password, "1");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApplied]);

  return (
    <BaseHeadNoindex
      title={routes.memberEntry.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        {!isApplied && (
          <MemberEntryForm
            customer={customer}
            setIsApplied={setIsApplied}
            setSigninData={setSigninData}
          />
        )}
      </section>
    </BaseHeadNoindex>
  );
};

export default MemberEntry;
