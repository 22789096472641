import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Typography,
} from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";

interface CustomModerationPanelProps {
  // 選択されているチャンネルの参加者一覧。
  channelMembersList: {
    userId: string;
    metaData: { type: string };
    nickname: string;
    plainProfileUrl?: string;
  }[];
}

export const ChatCustomModerationPanel = ({
  channelMembersList,
}: CustomModerationPanelProps) => {
  const transformedChannelList = channelMembersList.map((list) => ({
    userId: list.userId,
    type:
      list.metaData.type === "member"
        ? ("会員" as "会員")
        : ("社員" as "社員"),
    nickname: list.nickname,
    profileIcon: list.plainProfileUrl || "",
  }));

  return (
    <Accordion style={{ margin: "0px" }}>
      <AccordionSummary
        style={{
          minHeight: "40px",
          display: "flex",
          alignItems: "center",
        }}
        expandIcon={<ExpandMore />}
        aria-controls="channel-list-content"
        id="channel-list-content"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <PeopleIcon />
          <Typography style={{ fontSize: "14px", marginLeft: "8px" }}>
            参加者
          </Typography>
          <Avatar
            style={{
              width: "25px",
              height: "25px",
              marginLeft: "8px",
              fontSize: "0.75rem",
              backgroundColor: "#e0e0e0",
              color: "#000",
            }}
          >
            {transformedChannelList.length}
          </Avatar>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: "0px" }}>
        <Box
          style={{
            maxHeight: "30vh",
            overflowY: "auto",
            padding: "0 16px",
            width: "100%",
          }}
        >
          <Grid container spacing={1} style={{ marginBottom: "8px" }}>
            <Grid item xs={3}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                区分
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                ニックネーム
              </Typography>
            </Grid>
          </Grid>
          {transformedChannelList.map((member) => (
            <Grid
              container
              spacing={1}
              key={member.userId}
              style={{ marginBottom: "8px" }}
            >
              <Grid item xs={3} style={{ borderRight: "1px solid #DDDDDD" }}>
                <Typography style={{ fontSize: "14px" }}>
                  {member.type}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={member.profileIcon}
                    alt={member.nickname}
                    style={{ width: 20, height: 20, marginRight: "8px" }}
                  />
                  <Typography style={{ fontSize: "14px" }}>
                    {member.nickname}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
