import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import STFooter from "../../assets/styles/organisms/footer.module.scss";
import TApiResult from "../../types/api/TApiResult";
import { TApiMenuSettingsResult, fetchMenuSettings } from "../../utils/api/fetchMenuSettings";

type Props = {
  hasBackground?: boolean;
  hasNavigation?: boolean;
};

type FooterLink = {
  id: number;
  order: number;
  name: string;
  url: string;
};

const FOOTER_LOCATION_ID = 15;

const Footer: React.FC<Props> = (props: Props) => {
  // const footerLinks: FooterLink[] = [
  //   { id: 1, name: "初めての方へ", url: routes.beginner.path },
  //   { id: 2, name: "利用規約", url: routes.term.path },
  //   { id: 3, name: "プライバシーポリシー", url: routes.privacy.path },
  //   { id: 4, name: "お問い合わせ", url: routes.contact.path },
  //   { id: 5, name: "よくあるご質問", url: routes.faq.path },
  // ];
  const [footerLinks, setFooterLinks] = useState<FooterLink[]>([]);
  const urlPattern = /^http.+/;

  useEffect(() => {
    fetchMenuSettings(FOOTER_LOCATION_ID).then(
      (res: TApiResult & TApiMenuSettingsResult) => {
        if (!res.isSuccess || !res.result)
          return
        const footerLinks = res.result.map((res) => {
          return {
            id: res.id,
            siteId: res.site_id,
            locationId: res.location_id,
            order: res.order,
            icon: res.icon,
            url: res.url,
            name: res.name,
            locationName: res.location_name,
          };
        });
        const newFooterLinks = footerLinks.filter(v => Boolean(v.name));
        newFooterLinks.sort((x, y) => {
          return x.order - y.order;
        });
        setFooterLinks(newFooterLinks);
      }
    )
  }, [])

  return (
    <footer
      className={`${STFooter.footer_new}`}
    >
      <div className={STFooter.footer_block}>
        <div className={`${STFooter.footer_container_new} ${!props.hasNavigation && STFooter.footer_container_new_no_navi}`}>
          <ul className={STFooter.footer_nav_new}>
            {footerLinks.map((footerLink) => (
              <li key={footerLink.id} className={STFooter.footer_nav_item_new}>
                {!urlPattern.test(footerLink.url) ? (
                  <Link
                    to={footerLink.url}
                    className={STFooter.footer_nav_item_link_new}
                  >
                    {footerLink.name}
                    <div></div>
                  </Link>
                ) : (
                  <a
                    href={footerLink.url}
                    className={STFooter.footer_nav_item_link_new}
                  >
                    {footerLink.name}
                    <div></div>
                  </a>
                )}
              </li>
            ))}
          </ul>
          <div className={STFooter.footer_copy_right_new}>
            ©&nbsp;LIB CONSULTING CO.,LTD ALL RIGHTS RESERVED
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
