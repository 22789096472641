import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { TAppContactFormItem } from "../../types/TAppContactFormItem";
import { TAppFormError } from "../../types/TAppFormError";
import { ContactForm as TContactForm } from "../../types/form/contactForm";
import { fetchMemberDetail } from "../../utils/api/fetchMemberDetail";
import { postContactDetail } from "../../utils/api/postContactDetail";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import useMultipleClickPreventer from "../molecules/UseMultipleClickPreventer";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import ContactApplyCard from "../organisms/ContactApplyCard";
import ContactForm from "../organisms/ContactForm";
import ContactFormConfirm from "../organisms/ContactFormConfirm";
import BaseHeadNoindex from "../templates/BaseHeadNoindex";

const Contact: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const initialForm: TContactForm = {
    sei: "",
    mei: "",
    kanaSei: "",
    kanaMei: "",
    email: "",
    emailConfirm: "",
    tel: "",
    body: "",
  };
  const [form, setForm] = useState<TContactForm>(initialForm);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<
    TAppFormError<TAppContactFormItem>[]
  >([]);
  const [apply, setApply] = useState<boolean>(false);
  const auth = getAuth();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!auth.currentUser) return;
    fetchMemberDetail().then((res) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newForm = {
        sei: res.result.sei,
        mei: res.result.mei,
        kanaSei: res.result.kana_sei,
        kanaMei: res.result.kana_mei,
        email: res.result.email,
        emailConfirm: res.result.email,
        tel: res.result.tel,
        body: "",
      };
      setForm(newForm);
    });
  }, []);

  const hundleApply = useMultipleClickPreventer(() => {
    return postContactDetail(
      form.sei,
      form.mei,
      form.kanaSei,
      form.kanaMei,
      form.email,
      form.tel,
      form.body
    ).then((res) => {
      if (!res.isSuccess) return;
      setApply(true);
      postMemberAccessLog(ACCESS_LOG_ACTION.contact, document.referrer);
    });
  });

  return (
    <BaseHeadNoindex
      title={routes.contact.title}
      login={Boolean(auth.currentUser)}
      subdomain={subdomain}
      hasNavigationBar={true}
    >
      <section className={STUtils.container_large}>
        {!confirm ? (
          <ContactForm
            form={form}
            setForm={setForm}
            setConfirm={setConfirm}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        ) : !apply ? (
          <ContactFormConfirm
            form={form}
            hundleApply={hundleApply}
            setConfirm={setConfirm}
          />
        ) : (
          <ContactApplyCard />
        )}
        <div>
          <BottomNavigationBar />
        </div>
      </section>
    </BaseHeadNoindex>
  );
};

export default Contact;
