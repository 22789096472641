import React, { useState } from 'react'
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import PWAInstallationModal from '../organisms/PWAInstallationModal';

const AddToHomeScreen = () => {

    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <>
            <div className={STMemberInfoCard.add_to_home_screen} onClick={() => setPopupOpen(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="white" stroke="#222222"/>
                    <rect width="1" height="6" rx="0.5" transform="matrix(-1 0 0 1 8.5 5)" fill="#222222"/>
                    <rect width="1" height="6" rx="0.5" transform="matrix(-1 0 0 1 8.5 5)" fill="#222222"/>
                    <rect x="11" y="8.5" width="6" height="1" rx="0.5" transform="rotate(180 11 8.5)" fill="#222222"/>
                    <rect x="11" y="8.5" width="6" height="1" rx="0.5" transform="rotate(180 11 8.5)" fill="#222222"/>
                </svg>
                <h4>アプリとしてホームに追加</h4>
            </div>
            {popupOpen && 
                <PWAInstallationModal 
                    popupOpen
                    closePopup={() => setPopupOpen(false)}
                />
            }
        </>
    )
}

export default AddToHomeScreen
