import React, { useEffect, useState } from "react";
import STVideoCard from "../../assets/styles/molecules/videoCard.module.scss";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import setEmbedScript from "../../utils/setEmbedScript";
import VideoModal from "../organisms/VideoModal";

type Props = {
  title: string;
  className: string;
  linkUrl: string;
  content: string;
};

const VideoCard: React.FC<Props> = ({ title, linkUrl, content, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  type EmbedProps = {
    content: string;
    isModal: boolean;
  };
  const EmbedContainer: React.FC<{ isModal: boolean }> = ({
    children,
    isModal,
  }) => (
    <div
      style={{
        width: isModal ? "100%" : "180px",
        height: isModal ? "100%" : "320px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {children}
    </div>
  );

  const useScale = (
    breakpoint: number,
    smallScale: number,
    largeScale: number
  ) => {
    const [scale, setScale] = useState(
      window.innerWidth < breakpoint ? smallScale : largeScale
    );

    useEffect(() => {
      const handleResize = () => {
        setScale(window.innerWidth < breakpoint ? smallScale : largeScale);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [breakpoint, smallScale, largeScale]);

    return scale;
  };

  const handleVideoClick = () => {
    if(window.innerWidth < 800) {
      (window.location.href = linkUrl)
    } else {
      const videoUrl = window.location.origin + linkUrl;
      postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer, videoUrl);
      setIsModalOpen(true);
    } 
  };

  const TikTokEmbed: React.FC<EmbedProps> = ({ content, isModal }) => {
    useEffect(() => {
      setEmbedScript("TikTok", content);
    }, [content]);
    return (
      <EmbedContainer isModal={isModal}>
        <div
          style={{
            width: "100%",
            height: "100%",
            position: isModal ? "static" : "absolute",
            transform: isModal
              ? "none"
              : `translate(-35%, -50%) scale(0.33)`,
            top: `${30}%`,
            left: `${'calc((100% - 97px)/2)'}`,
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </EmbedContainer>
    );
  };

  const InstagramEmbed: React.FC<EmbedProps & { isModal: boolean }> = ({
    content,
    isModal,
  }) => {
    useEffect(() => {
      setEmbedScript("Instagram", content, isModal);
    }, [content, isModal]);
    return (
      <EmbedContainer isModal={isModal}>
        <div
          className={STVideoCard.instagram}
          style={{
            transform: isModal
              ? "none"
              : `scale(${0.36}) translate(${'-1%'}, -50%)`,
            transformOrigin: "top left",
            position: isModal ? "static" : "absolute",
            top: '50%',
            left: `${'calc((100% - 117px)/2)'}`,
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </EmbedContainer>
    );
  };

  const YoutubeEmbed: React.FC<EmbedProps> = ({ content, isModal }) => {
    const [isShort, setIsShort] = React.useState(false);
    const longScale = useScale(800, 0.4, 0.35);
    const longLeft = useScale(800, 14, 10);

    useEffect(() => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const iframe = doc.querySelector("iframe");

      if (iframe) {
        const width = parseInt(iframe.width, 10);
        const height = parseInt(iframe.height, 10);

        if (width && height) {
          const aspectRatio = height / width;
          setIsShort(aspectRatio > 1);
        }
      }
    }, [content]);
    return (
      <EmbedContainer isModal={isModal}>
        <div
          style={{
            width: !isShort ? "100%" : undefined,
            height: !isShort ? "100%" : undefined,
            position: isModal || isShort ? "static" : "absolute",
            transform:
              isModal || isShort
                ? "none"
                : `translate(-50%, -50%) scale(${longScale})`,
            top: !isShort ? "50%" : undefined,
            left: !isShort ? `${longLeft}%` : undefined,
          }}
          className={isShort && !isModalOpen ? STVideoCard.youtube_embed : ""}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </EmbedContainer>
    );
  };

  // 動画媒体によってコンポーネント変更
  let EmbedComponent;
  if (content.includes("tiktok.com")) {
    EmbedComponent = <TikTokEmbed content={content} isModal={isModalOpen} />;
  } else if (content.includes("instagram.com")) {
    EmbedComponent = <InstagramEmbed content={content} isModal={isModalOpen} />;
  } else {
    EmbedComponent = <YoutubeEmbed content={content} isModal={isModalOpen} />;
  }
  const Blocker: React.FC = () => (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1, // 動画より前面に表示して再生をブロック
      }}
    />
  );
  return (
    <div className={STVideoCard.component_new}>
      <div className={STVideoCard.container_new} onClick={handleVideoClick}>
        {EmbedComponent}
        <Blocker />
      </div>
      <div className={STVideoCard.title_new}>{title}</div>
      {isModalOpen && (
        <VideoModal
          EmbedComponent={EmbedComponent}
          isModalOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};
export default VideoCard;
