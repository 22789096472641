import * as React from "react";
import { SVGProps } from "react";
import { SiteContext } from "../../../providers/SiteProvider";

const SvgClose = (props: SVGProps<SVGSVGElement>) => {
  const site = React.useContext(SiteContext);
  const color = site.mainColor;
  return (
    <svg
      width={22}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.705 4.41A.917.917 0 0 0 4.41 5.706L9.703 11l-5.295 5.294a.917.917 0 1 0 1.296 1.296L11 12.296l5.294 5.294a.917.917 0 0 0 1.296-1.296L12.295 11l5.294-5.294a.917.917 0 0 0-1.296-1.296l-5.294 5.294-5.294-5.295v.001Z"
        fill={color}
      />
    </svg>
  )
}

export default SvgClose;
