/* eslint-disable max-lines */
export const jaStrings = {
  // アンケートテンプレート
  pagePrevText: "前へ",
  pageNextText: "次へ",
  completeText: "送信",
  modalCancelButtonText: "キャンセル",
  invalidEmail: "メールアドレスの形式で入力してください。",
  requiredInAllRowsError: '必須項目です。',
  filePlaceholder: "ファイルをドラッグ アンド ドロップするか、ボタンをクリックしてアップロードするファイルを選択します。",
  survey: {
    edit: '編集',
    externalHelpLink: 'アンケート作成方法の動画を見る',
    externalHelpLinkUrl:
      'https://www.youtube.com/channel/UCH2ru9okIcooNZfpIbyq4qQ?view_as=subscriber',
    dropQuestion: 'ツールボックスから質問をここにドロップしてください。',
    addLogicItem: 'アンケートの流れをカスタマイズするルールを作成する。',
    copy: 'コピー',
    duplicate: '複製',
    addToToolbox: 'ツールボックスに追加',
    deletePanel: 'パネルを削除',
    deleteQuestion: '質問を削除',
    convertTo: '変換',
    drag: '要素をドラッグ',
    license:
      "ライブラリを使用するには、<a href='https://surveyjs.io/licensing'>開発者ライセンス</a>が必要です。アクティブなライセンスをお持ちの場合は、<a href='https://surveyjs.io/remove-alert-banner'>ライセンスキーを設定</a>し、最新バージョンを使用していることを確認してください。",
  },
  // 作成者タブ
  tabs: {
    preview: 'プレビュー',
    theme: 'テーマ',
    translation: '翻訳',
    designer: '編集',
    editor: 'JSONエディタ',
    logic: 'ロジック',
  },
  // 質問タイプ
  qt: {
    default: 'デフォルト',
    checkbox: 'チェックボックス',
    comment: '長文テキスト',
    imagepicker: '画像選択',
    ranking: 'ランキング',
    image: '画像',
    dropdown: 'ドロップダウン',
    tagbox: '複数選択ドロップダウン',
    file: 'ファイルアップロード',
    html: 'HTML',
    matrix: '単一選択マトリックス',
    matrixdropdown: '複数選択マトリックス',
    matrixdynamic: '動的マトリックス',
    multipletext: '複数テキストボックス',
    panel: 'パネル',
    paneldynamic: '動的パネル',
    radiogroup: 'ラジオボタングループ',
    rating: '評価スケール',
    text: 'テキストボックス（単一行）',
    boolean: '二者択一',
    expression: '式 (読み取り専用)',
    signaturepad: '署名',
    buttongroup: 'ボタングループ',
  },
  toolboxCategories: {
    general: '全体の設定',
    choice: '選択肢の質問',
    text: 'テキスト入力の質問',
    containers: 'コンテナー',
    matrix: 'マトリックスの質問',
    misc: 'その他',
  },
  // SurveyJS Creatorの文字列
  ed: {
    defaultLocale: 'デフォルト ({0})',
    survey: 'アンケート',
    settings: '設定',
    settingsTooltip: '設定を開く',
    surveySettings: 'アンケート設定',
    surveySettingsTooltip: 'アンケート設定',
    themeSettings: 'テーマ設定',
    themeSettingsTooltip: 'テーマ設定',
    showPanel: 'パネルを表示',
    hidePanel: 'パネルを非表示',
    prevSelected: '前を選択',
    nextSelected: '次を選択',
    prevFocus: '前にフォーカス',
    nextFocus: '次にフォーカス',
    surveyTypeName: 'アンケート',
    pageTypeName: 'ページ',
    panelTypeName: 'パネル',
    questionTypeName: '質問',
    columnTypeName: '列',
    addNewPage: '新しいページを追加',
    moveRight: '右にスクロール',
    moveLeft: '左にスクロール',
    deletePage: 'ページを削除',
    editPage: 'ページを編集',
    edit: '編集',
    newPageName: 'ページ',
    newQuestionName: '質問',
    newPanelName: 'パネル',
    newTextItemName: 'テキスト',
    defaultV2Theme: 'デフォルト',
    modernTheme: 'モダン',
    defaultTheme: 'デフォルト (レガシー)',
    testSurveyAgain: 'アンケートを再度プレビュー',
    testSurveyWidth: 'アンケート幅: ',
    navigateToMsg: '次に移動する必要がありました：',
    saveSurvey: 'アンケートを保存',
    saveSurveyTooltip: 'アンケートを保存',
    saveTheme: 'テーマを保存',
    saveThemeTooltip: 'テーマを保存',
    jsonHideErrors: 'エラーを非表示',
    jsonShowErrors: 'エラーを表示',
    undo: '元に戻す',
    redo: 'やり直し',
    undoTooltip: '最後の変更を元に戻す',
    redoTooltip: '変更をやり直す',
    showMoreChoices: 'さらに表示',
    showLessChoices: '表示を減らす',
    copy: 'コピー',
    cut: '切り取り',
    paste: '貼り付け',
    copyTooltip: '選択範囲をクリップボードにコピー',
    cutTooltip: '選択範囲をクリップボードに切り取り',
    pasteTooltip: 'クリップボードから貼り付け',
    options: 'オプション',
    generateValidJSON: '有効なJSONを生成',
    generateReadableJSON: '読みやすいJSONを生成',
    toolbox: 'ツールボックス',
    'property-grid': 'プロパティ',
    toolboxFilteredTextPlaceholder: '検索...',
    toolboxNoResultsFound: '結果が見つかりません',
    propertyGridFilteredTextPlaceholder: '検索...',
    propertyGridNoResultsFound: '結果が見つかりません',
    correctJSON: 'JSONを修正してください。',
    surveyResults: 'アンケート結果: ',
    surveyResultsTable: '表として',
    surveyResultsJson: 'JSONとして',
    resultsTitle: '質問タイトル',
    resultsName: '質問名',
    resultsValue: '回答値',
    resultsDisplayValue: '表示値',
    modified: '変更済み',
    saving: '保存中',
    saved: '保存済み',
    propertyEditorError: 'エラー',
    saveError: 'エラー！ エディタの内容は保存されていません。',
    translationPropertyGridTitle: '言語設定',
    themePropertyGridTitle: 'テーマ設定',
    translationLanguages: '言語',
    translationDeleteLanguage: 'この言語のすべての文字列を削除してもよろしいですか？',
    translationAddLanguage: '翻訳する言語を選択',
    translationShowAllStrings: 'すべての文字列',
    translationShowUsedStringsOnly: '使用される文字列のみ',
    translationShowAllPages: 'すべてのページ',
    translationNoStrings: '翻訳する文字列がありません。フィルターを変更してください。',
    translationExportToSCVButton: 'CSVにエクスポート',
    translationImportFromSCVButton: 'CSVからインポート',
    translateUsigAI: 'すべて自動翻訳',
    translateUsigAIFrom: '翻訳元: ',
    translationDialogTitle: '未翻訳の文字列',
    translationMergeLocaleWithDefault: '{0}をデフォルトロケールとマージ',
    translationPlaceHolder: '翻訳...',
    translationSource: 'ソース: ',
    translationTarget: 'ターゲット: ',
    themeExportButton: 'エクスポート',
    themeImportButton: 'インポート',
    surveyJsonExportButton: 'エクスポート',
    surveyJsonImportButton: 'インポート',
    surveyJsonCopyButton: 'クリップボードにコピー',
    themeResetButton: 'テーマ設定をデフォルトにリセット',
    themeResetConfirmation: '本当にテーマをリセットしますか？ すべてのカスタマイズが失われます。',
    themeResetConfirmationOk: 'はい、テーマをリセットします',
    bold: '太字',
    italic: 'イタリック',
    underline: '下線',
    addNewQuestion: '質問を追加',
    selectPage: 'ページを選択...',
    carryForwardChoicesCopied: '選択肢がコピーされました',
    choicesLoadedFromWebText: '選択肢はウェブサービスから読み込まれます。',
    choicesLoadedFromWebLinkText: '設定に移動',
    choicesLoadedFromWebPreviewTitle: '読み込まれた選択肢オプションのプレビュー',
    htmlPlaceHolder: 'HTMLコンテンツがここに表示されます。',
    panelPlaceHolder: 'ツールボックスから質問をここにドロップしてください。',
    surveyPlaceholderTitle:
      'アンケートは空です。ツールボックスから要素をドラッグするか、下のボタンをクリックしてください。',
    previewPlaceholderTitle: '質問項目を1件以上設定してください',
    previewPlaceholderDescription: '',
    surveyPlaceholderDescription: '',
    pagePlaceHolder:
      'ページは空です。ツールボックスから要素をドラッグするか、下のボタンをクリックしてください。',
    imagePlaceHolder:
      'ここに画像をドラッグ＆ドロップするか、下のボタンをクリックしてアップロードする画像を選択してください',
    surveyPlaceHolderMobile:
      '下の「質問を追加」ボタンをクリックしてフォームの作成を開始してください。',
    pagePlaceHolderMobile:
      '下の「質問を追加」ボタンをクリックしてページに新しい要素を追加してください。',
    panelPlaceHolderMobile:
      '下の「質問を追加」ボタンをクリックしてパネルに新しい要素を追加してください。',
    imagePlaceHolderMobile: '下のボタンをクリックしてアップロードする画像を選択してください',
    imageChooseImage: '画像を選択',
    addNewTypeQuestion: '{0}を追加', //{0} はローカライズ可能な質問タイプ
    chooseLogoPlaceholder: '[ロゴ]',
    choices_Item: 'アイテム ',
    selectFile: 'ファイルを選択',
    removeFile: 'ファイルを削除',
    lg: {
      addNewItem: '新しいルールを追加',
      empty_tab: 'アンケートの流れをカスタマイズするルールを作成します。',
      page_visibilityName: 'ページの表示/非表示',
      page_enableName: 'ページの有効化/無効化',
      page_requireName: 'ページを必須にする',
      panel_visibilityName: 'パネルの表示/非表示',
      panel_enableName: 'パネルの有効化/無効化',
      panel_requireName: 'パネルを必須にする',
      question_visibilityName: '質問の表示/非表示',
      question_enableName: '質問の有効化/無効化',
      question_requireName: '質問を必須にする',
      question_resetValueName: '質問の値をリセット',
      question_setValueName: '質問の値を設定',
      column_visibilityName: '列の表示/非表示',
      column_enableName: '列の有効化/無効化',
      column_requireName: '列を必須にする',
      column_resetValueName: '列の値をリセット',
      column_setValueName: '列の値を設定',
      trigger_completeName: 'アンケートを完了',
      trigger_setvalueName: '回答を設定',
      trigger_copyvalueName: '回答をコピー',
      trigger_skipName: '質問にスキップ',
      trigger_runExpressionName: '式を実行',
      completedHtmlOnConditionName: '「ありがとうございました」ページのカスタムテキスト',
      page_visibilityDescription:
        '論理式がtrueを返す場合、ページを表示します。それ以外の場合は非表示にします。',
      panel_visibilityDescription:
        '論理式がtrueを返す場合、パネルを表示します。それ以外の場合は非表示にします。',
      panel_enableDescription:
        '論理式がtrueを返す場合、パネルとその中のすべての要素を有効にします。それ以外の場合は無効にします。',
      question_visibilityDescription:
        '論理式がtrueを返す場合、質問を表示します。それ以外の場合は非表示にします。',
      question_enableDescription:
        '論理式がtrueを返す場合、質問を有効にします。それ以外の場合は無効にします。',
      question_requireDescription: '論理式がtrueを返す場合、質問が必須になります。',
      trigger_completeDescription:
        '論理式がtrueを返す場合、アンケートが完了し、エンドユーザーに「ありがとうございました」ページが表示されます。',
      trigger_setvalueDescription:
        '論理式で使用される質問の値が変更され、論理式がtrueを返す場合、設定された値が選択された質問に割り当てられます。',
      trigger_copyvalueDescription:
        '論理式で使用される質問の値が変更され、論理式がtrueを返す場合、選択された質問の値が別の選択された質問にコピーされます。',
      trigger_skipDescription:
        '論理式がtrueを返す場合、アンケートは選択された質問にスキップ/フォーカスします。',
      trigger_runExpressionDescription:
        '論理式がtrueを返す場合、カスタム式が実行されます。オプションでこの式の結果を選択した質問に割り当てることができます。',
      completedHtmlOnConditionDescription:
        '論理式がtrueを返す場合、デフォルトの「ありがとうございました」ページのテキストが指定されたテキストに変更されます。',
      itemExpressionText: "式: '{0}' がtrueを返す場合", //{0} - the expression
      itemEmptyExpressionText: '新しいルール',
      page_visibilityText: 'ページ {0} を表示する', //{0} page name
      panel_visibilityText: 'パネル {0} を表示する', //{0} panel name
      panel_enableText: 'パネル {0} を有効にする', //{0} panel name
      question_visibilityText: '質問 {0} を表示する', //{0} question name
      question_enableText: '質問 {0} を有効にする', //{0} question name
      question_requireText: '質問 {0} を必須にする', //{0} question name
      question_resetValueText: '質問のリセット値: {0}', //{0} question name
      question_setValueText: '値の割り当て: {1} を質問: {0} に', //{0} question name, {1} setValue
      column_visibilityText: '質問 {1} の列 {0} を表示する', //{0} column name, {1} question name
      column_enableText: '質問 {1} の列 {0} を有効にする', //{0} column name, {1} question name
      column_requireText: '質問 {1} の列 {0} を必須にする', //{0} column name, {1} question name
      column_resetValueText: '列のセル値をリセット: {0}', //{0} column name
      column_setValueText: 'セル値の割り当て: {1} を列: {0} に', //{0} column name, {1} setValue
      setValueExpressionPlaceholder: '結果が目標の質問に割り当てられる式。',
      trigger_completeText: 'アンケートが完了する',
      trigger_setvalueText: '質問: {0} に値 {1} を設定する', //{0} question name, {1} setValue
      trigger_setvalueEmptyText: '明確な質問値: {0}', //{0} question name
      trigger_copyvalueText: '質問: {0} に質問 {1} から値をコピーする', //{0} and {1} question names
      trigger_skipText: 'アンケートを質問 {0} にスキップする', //{0} question name
      trigger_runExpressionText1: "式: '{0}' を実行する", //{0} expression
      trigger_runExpressionText2: ' そしてその結果を質問: {0} に設定する', //{0} question name
      completedHtmlOnConditionText:
        '「ありがとうございました」ページにカスタムテキストを表示する。',
      showAllQuestions: 'すべての質問',
      showAllActionTypes: 'すべてのアクションタイプ',
      conditions: '条件',
      actions: 'アクション',
      expressionEditorTitle: '条件を定義する',
      actionsEditorTitle: 'アクションを定義する',
      deleteAction: 'アクションを削除',
      addNewAction: '新しいアクションを追加',
      selectedActionCaption: 'アクションを選択...',
      expressionInvalid: '論理式が空または無効です。修正してください。',
      noActionError: '少なくとも1つのアクションを追加してください。',
      actionInvalid: 'アクションの問題を解決してください。',
      uncompletedRule_title: '論理ルールが不完全です',
      uncompletedRule_text:
        '一部の論理ルールを完了していません。ここでタブを離れると、変更は失われます。変更を完了せずにタブを離れますか？',
      uncompletedRule_apply: 'はい',
      uncompletedRule_cancel: 'いいえ、ルールを完成させたいです',
    },
  },
  // プロパティエディタ
  // Properties
  p: {
    title: {
      name: 'タイトル',
      title: '「名前」と同じ場合は空白のままにしてください',
    },
    multiSelect: '複数選択を許可',
    showLabel: '画像のキャプションを表示する',
    value: '値',
    tabAlign: 'タブの配置',
    description: '説明',
    logoFit: 'ロゴのフィット',
    pages: 'ページ',
    questions: '質問',
    triggers: 'トリガー',
    calculatedValues: '計算値',
    surveyId: 'アンケートID',
    surveyPostId: 'アンケート投稿ID',
    surveyShowDataSaving: 'アンケートでデータ保存を表示',
    questionDescriptionLocation: '質問の説明の位置',
    progressBarType: 'プログレスバーのタイプ',
    showTOC: '目次を表示',
    tocLocation: '目次の位置',
    questionTitlePattern: '質問タイトルのパターン',
    widthMode: '幅モード',
    showBrandInfo: 'ブランド情報を表示',
    useDisplayValuesInDynamicTexts: 'ダイナミックテキストで表示値を使用',
    visibleIf: '次の場合に表示',
    titleLocation: 'タイトルの位置',
    descriptionLocation: '説明の位置',
    defaultValueExpression: 'デフォルト値の式',
    requiredIf: '次の場合に必須',
    resetValueIf: '次の場合に値をリセット',
    setValueIf: '次の場合に値を設定',
    validators: 'バリデーター',
    bindings: 'バインディング',
    renderAs: '次のようにレンダリング',
    attachOriginalItems: 'オリジナルアイテムを添付',
    choices: '選択肢',
    choicesByUrl: 'URLによる選択肢',
    currency: '通貨',
    cellHint: 'セルのヒント',
    isUnique: '一意',
    showInMultipleColumns: '複数の列で表示',
    totalMaximumFractionDigits: '合計の最大小数桁数',
    totalMinimumFractionDigits: '合計の最小小数桁数',
    columns: '列',
    detailElements: '詳細要素',
    allowAdaptiveActions: 'アダプティブアクションを許可',
    defaultRowValue: 'デフォルトの行の値',
    detailPanelShowOnAdding: '追加時に詳細パネルを表示',
    choicesLazyLoadEnabled: '選択肢の遅延読み込みを有効にする',
    choicesLazyLoadPageSize: '選択肢の遅延読み込みページサイズ',
    inputFieldComponent: '入力フィールドコンポーネント',
    itemComponent: 'アイテムコンポーネント',
    min: '最小',
    max: '最大',
    minValueExpression: '最小値の式',
    maxValueExpression: '最大値の式',
    step: 'ステップ',
    dataList: 'データリスト',
    itemSize: 'アイテムサイズ',
    elements: '要素',
    content: 'コンテンツ',
    navigationButtonsVisibility: 'ナビゲーションボタンの可視性',
    navigationTitle: 'ナビゲーションタイトル',
    navigationDescription: 'ナビゲーションの説明',
    longTap: '長押し',
    autoGrow: '自動拡大',
    allowResize: 'サイズ変更を許可',
    acceptCarriageReturn: 'キャリッジリターンを受け入れる',
    displayMode: '表示モード',
    rateType: 'レートタイプ',
    label: 'ラベル',
    contentMode: 'コンテンツモード',
    imageFit: '画像のフィット',
    altText: '代替テキスト',
    height: '高さ',
    penColor: 'ペンの色',
    backgroundColor: '背景色',
    templateElements: 'テンプレート要素',
    operator: '演算子',
    isVariable: '変数である',
    runExpression: '式を実行',
    showCaption: 'キャプションを表示',
    iconName: 'アイコン名',
    iconSize: 'アイコンサイズ',
    precision: '精度',
    matrixDragHandleArea: 'マトリックスドラッグハンドルエリア',
    backgroundImage: '背景画像',
    backgroundImageFit: '背景画像のフィット',
    backgroundImageAttachment: '背景画像の添付',
    backgroundOpacity: '背景の不透明度',
    selectToRankEnabled: 'ランク付けを有効にする',
    selectToRankAreasLayout: 'エリアのレイアウトをランク付け',
    allowCameraAccess: 'カメラアクセスを許可',
    scaleColorMode: 'スケールカラーモード',
    rateColorMode: 'レートカラーモード',
    copyDisplayValue: '表示値をコピー',
  },
  theme: {
    '--background': '背景色',
    '--background-dim-light': '背景の薄暗い光の色',
    '--primary-foreground': '主要な前景色',
    '--foreground': '前景色',
    '--base-unit': '基本単位',
    groupGeneral: '一般',
    groupAdvanced: '高度',
    groupHeader: 'ヘッダー',
    groupBackground: '背景',
    groupAppearance: '外観',
    themeName: 'テーマ',
    themeMode: '質問の外観',
    themeModePanels: 'デフォルト',
    themeModeLightweight: 'パネルなし',
    themePaletteLight: 'ライト',
    themePaletteDark: 'ダーク',
    primaryColor: 'アクセント色',
    primaryDefaultColor: 'デフォルト',
    primaryDarkColor: 'ホバー',
    primaryLightColor: '選択済み',
    backgroundDimColor: '背景色',
    backgroundImage: '背景画像',
    backgroundImageFitAuto: '自動',
    backgroundImageFitCover: 'カバー',
    backgroundImageFitContain: '含む',
    backgroundOpacity: '不透明度',
    backgroundImageAttachmentFixed: '固定',
    backgroundImageAttachmentScroll: 'スクロール',
    panelBackgroundTransparency: 'パネルの背景の不透明度',
    questionBackgroundTransparency: '質問の背景の不透明度',
    questionPanel: 'パネルの背景と角の半径',
    questionTitle: '質問タイトルのフォント',
    questionDescription: '質問の説明のフォント',
    editorPanel: '入力要素',
    editorFont: '入力要素のフォント',
    backcolor: 'デフォルトの背景',
    hovercolor: 'ホバーの背景',
    borderDecoration: 'ボーダーの装飾',
    accentBackground: 'アクセントの背景',
    accentForeground: 'アクセントの前景',
    primaryForecolor: 'デフォルトの色',
    primaryForecolorLight: '無効の色',
    linesColors: 'マイナーラインの色',
    borderDefault: '暗め',
    borderLight: '明るめ',
    fontFamily: 'フォントファミリー',
    fontSize: 'フォントサイズ',
    color: '色',
    placeholderColor: 'プレースホルダーの色',
    size: 'サイズ',
    fontWeightRegular: '普通',
    fontWeightHeavy: '太め',
    fontWeightSemiBold: '半太',
    fontWeightBold: '太字',
    scale: 'スケール',
    cornerRadius: '角の半径',
    surveyTitle: 'アンケートの名前のフォント',
    pageTitle: 'ページタイトルのフォント',
    pageDescription: 'ページ説明のフォント',
    boxShadowX: 'X',
    boxShadowY: 'Y',
    boxShadowAddRule: 'シャドウ効果を追加',
    opacity: '不透明度',
    boxShadowBlur: 'ぼかし',
    boxShadowSpread: '拡散',
    boxShadowDrop: 'ドロップ',
    boxShadowInner: '内側',
    questionShadow: 'シャドウ効果',
    editorShadow: '入力要素のシャドウ効果',
    names: {
      default: 'デフォルト',
      sharp: 'シャープ',
      borderless: 'ボーダーレス',
      flat: 'フラット',
      plain: 'プレーン',
      doubleborder: '二重ボーダー',
      layered: 'レイヤード',
      solid: 'ソリッド',
      threedimensional: '3D',
      contrast: 'コントラスト',
    },
    colors: {
      teal: 'ティール',
      blue: 'ブルー',
      purple: 'パープル',
      orchid: 'オーキッド',
      tulip: 'チューリップ',
      brown: 'ブラウン',
      green: 'グリーン',
    },
  },
};
