import React, { useEffect } from "react";
import { TPopupSettingResponse } from "../../api/popup_setting";
import STUtils from "../../assets/styles/utility.module.scss";
import useAutomaticPopup from "../../hooks/useAutomaticPopup";
import useModal from "../../hooks/useModal";
import useSaveHistoryToLocalStorage from "../../hooks/useSaveHistoryToLocalStorage";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import AddToHomeScreen from "../molecules/AddToHomeScreen";
import CategoryCardList from "../organisms/CategoryCardList";
import MemberInfoCardNew from "../organisms/MemberInforCardNew";
import MenuList from "../organisms/MenuList";
import News from "../organisms/News";
import Popup from "../organisms/Popup";
import RecommendContentsList from "../organisms/RecommendContentsList";
import RecommendVideosList from "../organisms/RecommendVideosList";
import BaseHead from "../templates/BaseHead";

const Top: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const {
    modalComponent: Modal,
    isOpenClickScratchBanner,
    handleOpenPopup,
    handleClosePopup,
    handleOpenBanner,
    handleCloseBanner,
  } = useModal(false);

  const { saveInvisibleDate, saveInvisibleUpdatedAt } =
    useSaveHistoryToLocalStorage();

  const {
    shouldDisplayWhenLoaded,
    scratchSetting,
    setScratchSetting,
    popupSetting,
    playedScratch,
    isLoading,
    error,
  } = useAutomaticPopup();

  useEffect(() => {
    postMemberAccessLog(ACCESS_LOG_ACTION.topPage, document.referrer);
  }, []);

  const onClickScratchBanner = () => {
    handleOpenPopup();
    handleOpenBanner();
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div style={{pointerEvents: 'none'}}>
       <BaseHead
        title={routes.top.title}
        login={true}
        subdomain={subdomain}
        hasBackground={true}
        hasNavigationBar={true}
      >
        <div className={STUtils.container_large}>
          {!isLoading && !error && (
            <Modal>
              <Popup
                popupSetting={popupSetting! as TPopupSettingResponse}
                scratchSetting={scratchSetting}
                playedScratch={playedScratch}
                isOpenClickScratchBanner={isOpenClickScratchBanner}
                handleClosePopup={handleClosePopup}
                handleCloseBanner={handleCloseBanner}
                saveInvisibleDate={saveInvisibleDate}
                saveInvisibleUpdatedAt={saveInvisibleUpdatedAt}
              />
            </Modal>
          )}
          <div onClick={handleOverlayClick}>
            <RecommendContentsList />
            <div
              style={{
                marginTop: window.innerWidth < 800 ? "-70px" : "-85px",
              }}
            >
              <MemberInfoCardNew inHeader={false} />
            </div>
            <div className={STUtils.dn_pc}>
              <AddToHomeScreen />
            </div>
            <MenuList
              scratchSetting={scratchSetting}
              onClickScratchBanner={onClickScratchBanner}
              setScratchSetting={setScratchSetting}
            />
            <News />
            <RecommendVideosList />
            <CategoryCardList />
          </div>
        </div>
      </BaseHead>
    </div>
  );
};

export default Top;
