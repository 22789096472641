import { useState } from "react";
import STSearchForm from "../../assets/styles/molecules/searchForm.module.scss";
import STUtils from "../../assets/styles/utility.module.scss";
import PAGE_CONFIG from "../../config/page";
import CONSTANTS from "../../utils/constants";
import Button from "../atoms/Button";
import Text from "../atoms/form/Text";
import SvgSearch from "../atoms/icons/Search";

type Props = {
  className: string;
  placeholder?: string;
  executeSearch: (query: string) => void;
};

const SearchForm: React.FC<Props> = (props: Props) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const handleChange = (value: string) => {
    if (value && value.length > CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT) {
      setQuery(value.substring(0, CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT));
      return;
    }
    setQuery(value);
  }

  const handleSearch = () => {
    setIsOpened(false);
    props.executeSearch(query);
  }

  return (
    <>
      <div className={`${props.className} ${STUtils.dn_sp}`}>
        {/* PC */}
        <div className={STSearchForm.root}>
          <div className={STSearchForm.form_new}>
            <Text
                className={STSearchForm.input_new}
                name='q'
                defaultValue={query}
                placeholder={'サイト内検索'}
                onChange={handleChange}
              />
            <Button
              className={`${STSearchForm.button_new} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
              buttonClassName={STSearchForm.button_inner}
              clickAction={handleSearch}
              text={
                <>
                  <SvgSearch/><span className={STSearchForm.button_text}>検索</span>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchForm;
