import React, { useContext, useEffect, useRef, useState } from "react";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import { GroupChannelListHeader } from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader";
import EditUserProfile from "@sendbird/uikit-react/EditUserProfile";
import "../../assets/styles/sendbird.css";
import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import GroupChannelHeader from "@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader";
import { GroupChannelUI } from "@sendbird/uikit-react/GroupChannel/components/GroupChannelUI";
import { MessageInputWrapper } from "@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper";
import { GroupChannelListItem } from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListItem";
import { UnreadMessageContext } from "../../providers/ChatProvider";
import { SiteContext } from "../../providers/SiteProvider";
import MessageSearch from "@sendbird/uikit-react/MessageSearch";
import Icon from "@sendbird/uikit-react/ui/Icon";
import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import ChatCustomSeparator from "../molecules/ChatCustomSeparator";
import { RenderCustomSeparatorProps } from "@sendbird/uikit-react/types/types";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";
import ChannelSettingsUI from "@sendbird/uikit-react/ChannelSettings/components/ChannelSettingsUI";
import { Box } from "@material-ui/core";
import { ChatCustomModerationPanel } from "./ChatCustomModerationPanel";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { useLocation } from "react-router-dom";

type Props = {
  channel: any;
  setChannel: Function;
  sendMailMessage: Function;
  fileMessageParams: any;
  setFileMessageParams: Function;
  setOpen: Function;
  handleFileChange: Function;
};

const ChatPc = ({
  channel,
  setChannel,
  sendMailMessage,
  fileMessageParams,
  setFileMessageParams,
  setOpen,
  handleFileChange,
}: Props) => {
  const { updateUnreadCount } = useContext(UnreadMessageContext);
  const groupChatTextHeader = useContext(SiteContext).groupChatTextHeader;
  const [showEditProfile, setShowEditProfile] = useState<boolean>(false);
  const [showSetting, setShowSetting] = useState<boolean>(false);
  const [isShowSearchMessage, setIsShowSearchMessage] =
    useState<boolean>(false);
  const { member } = useContext(LoginUserContext);
  const location = useLocation();
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputElement = document.getElementById("text-input");
    const sendbirdInput = document.getElementById(
      "sendbird-message-input-text-field"
    );
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter" && e.shiftKey && !e.isComposing) {
        e.preventDefault();
        e.stopPropagation();
        const sendButton = document.getElementById("send-id");
        sendButton?.click();
        sendbirdInput!.innerText = "";
      } else if (e.key === "Enter" && !e.isComposing) {
        e.preventDefault();
        e.stopPropagation();

        if (sendbirdInput) {
          const currentValue = sendbirdInput.innerText;
          const selection = window.getSelection();
          const range = selection?.getRangeAt(0);
          if (currentValue.length === range?.startOffset) {
            document.execCommand("insertHTML", false, "\n\n");
            sendbirdInput.scrollBy(0, 20);
          } else {
            document.execCommand("insertHTML", false, "\n");
            sendbirdInput.scrollBy(0, 20);
          }
        }
      }
    };
    inputElement?.removeEventListener("keydown", onKeyDown);
    inputElement?.addEventListener("keydown", onKeyDown);
  }, [channel]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div
        style={{
          display: "flex",
          maxWidth: "1200px",
          margin: "13px auto 0 auto",
          height: "calc(100vh - 120px)",
          maxHeight: "100%",
        }}
      >
        <GroupChannelList
          key={new Date().toString()}
          allowProfileEdit={true}
          renderUserProfile={() => <EditUserProfile />}
          selectedChannelUrl={channel ? channel.url : ""}
          onChannelSelect={(channel: any) => {
            if (channel) {
              updateUnreadCount(channel.url, 0);
              setChannel(channel);
            }
          }}
          onChannelCreated={(channel: any) => {
            setChannel(channel);
          }}
          renderHeader={() => (
            <GroupChannelListHeader onEdit={() => setShowEditProfile(true)} />
          )}
          renderChannelPreview={(channel: any) => {
            const memberIdInChannel = channel?.channel?.members?.map(
              (member: any) => member.userId
            );
            return memberIdInChannel.includes(member?.sendbirdUserId) ? (
              <GroupChannelListItem
                tabIndex={channel?.tabIndex}
                channel={channel?.channel}
                onClick={channel?.onClick}
                renderChannelAction={channel?.renderChannelAction}
              />
            ) : (
              <></>
            );
          }}
          channelListQueryParams={{ includeEmpty: true }}
        />
        {channel && (
          <GroupChannelProvider
            key={channel.url}
            onBeforeSendMultipleFilesMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendFileMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendUserMessage={(message) => {
              sendMailMessage();
              return message;
            }}
            channelUrl={channel.url}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <GroupChannelUI
                renderChannelHeader={() => (
                  <>
                    <GroupChannelHeader
                      renderRight={() => (
                        <>
                          {/* 日本語での検索が可能になってからコメントアウト解除 */}
                          {/* <Icon type='SEARCH' fillColor='PRIMARY' onClick={() => {setIsShowSearchMessage(!isShowSearchMessage)}}/> */}
                          <Icon
                            type="INFO"
                            fillColor="PRIMARY"
                            onClick={() => {
                              setShowSetting(!showSetting);
                            }}
                          />
                        </>
                      )}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {groupChatTextHeader}
                    </div>
                  </>
                )}
                renderMessageContent={(contentProps: any) => {
                  if (contentProps?.message?.message) {
                    if (contentProps?.message?.message.trim().length === 0) {
                      return <></>;
                    }
                  }
                  return <MessageContent {...contentProps} />;
                }}
                renderMessageInput={() => (
                  <div
                    id="text-input"
                    style={{
                      maxWidth: "878px",
                      height: "auto",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <MessageInputWrapper
                        renderFileUploadIcon={() => (
                          <>
                            <Icon
                              type="ATTACH"
                              fillColor="PRIMARY"
                              onClick={() => fileRef.current?.click()}
                            />
                            <input
                              type="file"
                              multiple
                              onChange={(e) => handleFileChange(e, fileRef)}
                              ref={fileRef}
                              style={{ display: "none" }}
                            />
                          </>
                        )}
                        renderSendMessageIcon={() => (
                          <div id="send-id">
                            <Icon type="SEND" fillColor="PRIMARY" />
                          </div>
                        )}
                      />
                      <span
                        style={{
                          alignSelf: "flex-end",
                          color: "rgba(0, 0, 0, 0.38)",
                          fontSize: "12px",
                          marginTop: "4px",
                          paddingRight: "24px",
                        }}
                      >
                        Shift+Enterキーで送信
                      </span>
                    </div>
                  </div>
                )}
                renderCustomSeparator={(props: RenderCustomSeparatorProps) => (
                  <ChatCustomSeparator timestamp={props.message.createdAt} />
                )}
              />
            </div>
          </GroupChannelProvider>
        )}
        {showEditProfile && (
          <EditUserProfile
            onCancel={() => setShowEditProfile(false)}
            onEditProfile={() => setShowEditProfile(false)}
            onThemeChange={() => null}
          />
        )}
        {channel && showSetting && (
          <ChannelSettingsProvider
            channelUrl={channel.url}
            onCloseClick={() => setShowSetting(!showSetting)}
          >
            <ChannelSettingsUI
              renderChannelProfile={() => <Box />}
              renderModerationPanel={() => (
                <ChatCustomModerationPanel
                  channelMembersList={channel.members}
                />
              )}
            />
          </ChannelSettingsProvider>
        )}
        {channel && isShowSearchMessage && (
          <MessageSearch
            onCloseClick={() => setIsShowSearchMessage(false)}
            channelUrl={channel.url}
          />
        )}
      </div>
    </>
  );
};

export default ChatPc;
