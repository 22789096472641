import { routes } from "../../router/Router";
import { useParams } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import BaseHead from "../templates/BaseHead";
import { getSubdomain } from "../../utils/getSubdomain";
import SurveyComponent from "../atoms/Survey";
import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  fetchSurveyForms,
  TApiSurveyFormResult,
} from "../../utils/api/fetchSurveyForms";
import TApiResult from "../../types/api/TApiResult";
import {
  postSurveyForms,
  TSurveyFormAnswerResponce,
} from "../../utils/api/postSurveyForms";
import { TSurveyFormsResponce } from "../../api/survey_forms/_id@string";
import { format } from "date-fns";

const Survey: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const auth = getAuth();
  const urlParams = useParams<{ id: string }>();
  const [survey, setSurvey] = useState<TSurveyFormsResponce>({
    id: 0,
    name: "",
    contract_company_id: 0,
    form_type: 1,
    is_template: false,
    image: "",
    is_active: false,
    start_date: undefined,
    end_date: undefined,
    form_schema: {},
    theme: {},
  });
  const [theme, setTheme] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [Message, setMessage] = useState({ status: "", message: "" });
  const setErrorMessage = (message: string) => {
    setMessage({
      status: "error",
      message: message,
    });
  };
  const setSuccessMessage = (message: string) => {
    setMessage({
      status: "success",
      message: message,
    });
  };
  function dataURItoBlob(dataURI: any) {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  // アンケート完了
  const onSurveySubmit = async (completedSurvey: any) => {
    const formData = new FormData();
    completedSurvey.jsonObj.pages.map(
      (v: {
        elements: [
          {
            is_email: boolean;
            name: string;
            survey_question_id?: number;
            survey_question_ids?: number[];
            type: string;
            is_name: boolean;
            rows?: any[];
            items?: any[];
            columns?: any[];
            rateValues?: any[];
            choices?: any[];
            otherText?: string;
          }
        ];
      }) => {
        const answerObj = v.elements.map((value) => {
          const questionId = String(value.survey_question_id);
          const answer = completedSurvey.valuesHash[value.name];
          // 名前とメールの処理
          if (value.is_name) {
            formData.append("name", answer);
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(answer));
          } else if (value.is_email) {
            formData.append("email", answer);
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(answer));
          }
        
          // ファイルタイプの処理
          else if (value.type === "file") {
            formData.append("survey_file_question_ids", questionId);
            const file = answer?.[0];
            const fileData = file
              ? new File([dataURItoBlob(file.content)], file.name, { type: file.type })
              : new File([], "empty", { type: "text/plain" }); // 空のファイルをセット
            formData.append("survey_file_answers", fileData);
          }
        
          // レーティングの処理
          else if (value.type === "rating") {
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(answer));
          }
        
          // マトリックスタイプの質問処理
          else if (value.type === "matrix") {
            value?.rows?.forEach((row,idx)=>{
              const rowQuestionId = row?.survey_question_id;
              const rowName = row?.value;
              const rowAnswer = completedSurvey.valuesHash?.[value.name]?.[rowName] ?? "";
              formData.append("survey_question_ids", String(rowQuestionId));
              formData.append("survey_answers", String(rowAnswer || ""));
            })
          } 
          // マルチテキストタイプの質問処理
          else if (value.type === "multipletext") {
            value?.items?.forEach((item,idx)=>{
              const itemQuestionId = item?.survey_question_id;
              const itemName = item?.name;
              const itemAnswer = completedSurvey.valuesHash?.[value.name]?.[itemName] ?? "";
              formData.append("survey_question_ids", String(itemQuestionId));
              formData.append("survey_answers", String(itemAnswer || ""));
            })

          } 

          // "other" オプションの処理（単一値）
          else if (value?.choices && value?.otherText && answer === 'other') {
            const otherTextAnswer = completedSurvey.valuesHash[value.name + "-Comment"];
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(otherTextAnswer));
          }

          // "other" オプションの処理（複数値）
          else if (value?.choices && value?.otherText && Array.isArray(answer) && answer.includes('other')) {
            const otherTextAnswer = completedSurvey.valuesHash[value.name + "-Comment"];
            // 配列の場合、カンマで連結
            const combinedAnswer = answer.filter((ans) => ans !== 'other').join(',') + (otherTextAnswer ? `,${otherTextAnswer}` : '');
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(combinedAnswer));
          }

          // その他の質問タイプ
          else if (value.type !== "image") {
            formData.append("survey_question_ids", questionId);
            formData.append("survey_answers", String(answer));
          }
        });
        return answerObj;
      }
    );
    if (!survey || !survey.name || !completedSurvey.valuesHash)
      setErrorMessage("アンケート情報の送信に失敗しました。");
    try {
      // アンケート結果送信API
      const date = format(new Date(), "yyyy-MM-dd HH:mm:ss");
      formData.append(
        "contract_company_id",
        String(survey.contract_company_id)
      );
      formData.append("filledin_date", date);
      setSuccessMessage("アンケート回答を送信しました。<br />ご協力いただき、ありがとうございました！");
      await postSurveyForms(urlParams.id, formData).then(
        (responce: TSurveyFormAnswerResponce) => {
          if (responce?.status_code) {
            setSuccessMessage("アンケート回答を送信しました。<br />ご協力いただき、ありがとうございました！");
            return;
          } else {
            setErrorMessage("アンケート情報の送信に失敗しました。");
            return;
          }
        }
      );
    } catch (e) {
      setErrorMessage("アンケート情報の送信に失敗しました。");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // アンケート取得API
        const responce = await fetchSurveyForms(urlParams.id).then(
          (res: TApiResult & TApiSurveyFormResult) => {
            if (
              !res.isSuccess ||
              !res.result ||
              // ここで、is_activeがtrueであればエラーにしないようにする
              (!res?.result?.start_date && !res?.result?.end_date && !res.result?.is_active)
            ) {
              setErrorMessage("アンケート情報の取得に失敗しました。");
            }
            return res.result;
          }
        );
        const now = new Date();
        const startDate = responce?.start_date ? new Date(responce.start_date) : null;
        const endDate = responce?.end_date ? new Date(responce.end_date) : null;
        // 新形式(SurveyJS)のアンケートでない場合
        if (responce?.form_type !== 1) {
          setErrorMessage("アンケート情報の取得に失敗しました。");
        }
        // 新形式(SurveyJS)のアンケートの場合
        else {
          if (responce?.is_active) {
            // 公開中の場合
            // 開始日あり、終了日あり　→　開始日 <= 現在日時 <= 終了日
            // 開始日あり、終了日なし　→　開始日 <= 現在日時
            // 開始日なし、終了日あり　→　現在日時 <= 終了日
            // 開始日なし、終了日なし　→　常に公開
            if ((startDate && endDate && startDate <= now && endDate >= now) 
              || (!startDate && endDate && endDate >= now )
              || (!startDate && !endDate) 
              || (startDate && !endDate && startDate <= now)) {
              // 公開期間内の場合
              // アンケートtitleのセット
              const formSchemaData = {
                ...responce.form_schema,
                title: responce?.name,
              };
              let responceData = {
                ...responce,
                title: responce?.name,
                form_schema: formSchemaData
              };
              responceData.form_schema?.pages?.map((page: any, idx: number) => {
                page.elements?.map((elm: any, key: number) => {
                  if (elm?.is_email && auth?.currentUser?.email) {
                    elm.defaultValue = auth?.currentUser?.email;
                  }
                  if (elm?.type === 'rating') {
                    const element = elm?.rateValues?.map((v: { text?: { customlocale?: string } }, k: number) => {
                      if (v?.text?.customlocale) {
                        return v.text.customlocale;
                      } else {
                        return v;
                      }
                    });
                    if (responceData?.form_schema?.pages?.[idx]?.elements?.[key]?.rateValues) {
                      responceData.form_schema.pages[idx].elements[key].rateValues = element;
                    }
                  }
                  // アンケート質問説明のカスタムロケール対応
                  if (elm?.description && elm?.description?.customlocale) {
                    const description = elm.description.customlocale ? elm.description.customlocale : elm.description;
                    if (responceData?.form_schema?.pages?.[idx]?.elements?.[key]?.description) {
                      responceData.form_schema.pages[idx].elements[key].description = description;
                    }
                  }
                  if (elm?.type === 'image') {
                    const image = elm?.imageLink?.customlocale ? elm.imageLink.customlocale : elm.imageLink;
                    if (responceData?.form_schema?.pages?.[idx]?.elements?.[key]?.imageLink) {
                      responceData.form_schema.pages[idx].elements[key].imageLink = image;
                    }
                  }
                });
              });
              setSurvey(responceData);
              setTheme(responce.theme);
            }  
            
            else {
              // 公開期間外の場合
              setErrorMessage("アンケートの公開期間外です");
            }
          } else {
            // 非公開の場合
            setErrorMessage("アンケートの公開期間外です");
          }  
        }
      } catch (e) {
        setErrorMessage("アンケート情報の取得に失敗しました。");
      }
      setIsLoading(false);
    };
    fetchData();
  }, [urlParams.id]);
  if (isLoading) {
    return <></>;
  }
  return (
    <BaseHead
      title={routes.survey.title}
      login={Boolean(auth.currentUser)}
      subdomain={subdomain}
      hasNavigationBar={false}
      showFooter={false}
    >
      <section className={STUtils.container_large}>
        {Message.message ? (
        <Box
          display="flex"
          flexDirection="column"
          margin="100px auto"
          bgcolor="white"
          height="20vh"
          borderRadius="20px"
          alignItems="center"
          justifyContent="center"
          boxShadow="0px 4px 15px rgba(0, 0, 0, 0.15)"
          width="95%"
        >
          {Message.message.split('<br />').map((line, index) => (
            <Typography
              key={index}
              component="p"
              style={
                Message.status === "error"
                  ? { color: "red", fontWeight: "Bold" }
                  : { fontWeight: "Bold" }
              }
            >
              {line}
            </Typography>
          ))}
        </Box>
        ) : (
          <Box
            width="95%"
            maxWidth="1300px"
            margin="20px auto"
            boxShadow="0px 4px 15px rgba(0, 0, 0, 0.15)"
          >
            <SurveyComponent
              json={survey?.form_schema}
              theme={theme}
              onComplete={onSurveySubmit}
            />
          </Box>
        )}
      </section>
    </BaseHead>
  );
};

export default Survey;
