import { useLocation } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import { routes } from "../../router/Router";
import { CONTENT_TYPE, TAppContentType } from "../../types/TAppContentType";
import { getSubdomain } from "../../utils/getSubdomain";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import InfoArticleList from "../organisms/InfoArticleList";
import BaseHead from "../templates/BaseHead";

const InformationList: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page") ? Number(query.get("page")) : 1;
  const list = query.get("list") ?? undefined;
  const category = query.get("category")
    ? Number(query.get("category"))
    : undefined;
  const title = query.get("title") ?? undefined;
  const categorySlug = query.get("category_slug") ?? undefined;
  const contentTypeIds: TAppContentType[] = [
    CONTENT_TYPE.information,
    CONTENT_TYPE.video,
  ]
  if(!list){
    contentTypeIds.push(CONTENT_TYPE.video);
  }

  const subdomain = getSubdomain(window.location.hostname);
  return (
    <BaseHead
      title={routes.informations.title}
      login={true}
      subdomain={subdomain}
      hasBackground={true}
      hasNavigationBar={true}
      hasBackButton={true}
    >
      <section className={STUtils.container_large}>
        <InfoArticleList
          requestPage={page}
          requestCategory={category}
          requestTitle={title}
          requestCategorySlug={categorySlug}
          contentTypeIds={contentTypeIds}
        />
        <div>
          <BottomNavigationBar />
        </div>
      </section>
    </BaseHead>
  );
};

export default InformationList;
