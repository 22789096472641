import { Link } from "react-router-dom";
import styled from "styled-components";
import STArticleLink from "../../assets/styles/molecules/articleLink.module.scss";
import PAGE_CONFIG from "../../config/page";
import { format } from "../../types/api/TApiDatetime";
import { contentSummary } from "../../types/contentSummary";
import getImageUrl from "../../utils/getImageUrl";
import { getSubdomain } from "../../utils/getSubdomain";
import Time from "../atoms/Time";
import LikeCard from "./LikeCard";

type Props = {
  subdomain: string;
  articleSummary: contentSummary;
  className: string;
};

const subdomain = getSubdomain(window.location.hostname);

const Container = styled.h3`
  @media only screen and (max-width: 799px) {
    &:after {
      right: 0;
    }
  }
  @media only screen and (min-width: 800px) {
    &:after {
      right: 20px;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(${getImageUrl(
      subdomain,
      PAGE_CONFIG.IMAGE_URL_PATH.SITE,
      PAGE_CONFIG.ARROW_ICON
    )});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -moz-calc(50% - 7px);
    top: -o-calc(50% - 7px);
    top: -webkit-calc(50% - 7px);
    top: calc(50% - 7px);
  }
`;

const ArticleLink: React.FC<Props> = (props: Props) => {
  return (
    <div className={STArticleLink.article_link_new}>
      {props.articleSummary.title.length > 54 ? (
        <Link to={props.articleSummary.linkUrl}>
          {props.articleSummary.title.substr(0, 54) + "…"}
        </Link>
      ) : (
        <Link to={props.articleSummary.linkUrl}>
          {props.articleSummary.title}
        </Link>
      )}
      <div className={STArticleLink.article_time_like_container}>
        <Time
          className={STArticleLink.time_new}
          time={format(props.articleSummary.createdAt, "yyyy.MM.dd")}
        />
        <LikeCard count={props.articleSummary.goodCount}/>
      </div>
    </div>
  );
};

export default ArticleLink;
