import { useLocation } from "react-router-dom";
import STUtils from "../../assets/styles/utility.module.scss";
import { routes } from "../../router/Router";
import { CONTENT_TYPE, TAppContentType } from "../../types/TAppContentType";
import { getSubdomain } from "../../utils/getSubdomain";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import InfoArticleList from "../organisms/InfoArticleList";
import BaseHead from "../templates/BaseHead";

const NewsList: React.FC = () => {
    const query = new URLSearchParams(useLocation().search);
    const page = query.get("page") ? Number(query.get("page")) : 1;
    const category = query.get("category")
        ? Number(query.get("category"))
        : undefined;
    const title = query.get("title") ?? undefined;
    const categorySlug = query.get("category_slug") ?? undefined;
    const contentTypeIds: TAppContentType[] = [
        CONTENT_TYPE.news
    ]

    const subdomain = getSubdomain(window.location.hostname);
    return (
        <BaseHead
            title={routes.informations.title}
            login={true}
            subdomain={subdomain}
            hasBackground={true}
            hasNavigationBar={true}
            hasBackButton={true}
        >
        <section className={STUtils.container_large}>
            <InfoArticleList
                requestPage={page}
                requestCategory={category}
                requestTitle={title}
                requestCategorySlug={categorySlug}
                contentTypeIds={contentTypeIds}
            />
            <div className={STUtils.bottom_bar}>
                <BottomNavigationBar />
            </div>
        </section>
        </BaseHead>
    );
}

export default NewsList;
