import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { UserCredential, onAuthStateChanged, getAuth } from "firebase/auth";
import { useEffect } from "react";
import { SiteContext } from "./SiteProvider";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { ChatProvider } from "./ChatProvider";
import { fetchMemberDetail } from "../utils/api/fetchMemberDetail";
import { TAppMember } from "../types/TAppMember";
import getStringSet from "../localization/chatStringSet";
import { ja } from "date-fns/locale";

type LoginUser = UserCredential["user"];

export type LoginUserContextType = {
  loginUser: LoginUser | null;
  setLoginUser: Dispatch<SetStateAction<LoginUser | null>>;
  member: TAppMember | undefined;
  setMember: Dispatch<SetStateAction<TAppMember | undefined>>;
};

export const LoginUserContext = createContext({} as LoginUserContextType);

export const LoginUserProvider = (props: { children: ReactNode }) => {
  const site = useContext(SiteContext);
  const { children } = props;
  const [loginUser, setLoginUser] = useState<LoginUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [member, setMember] = useState<TAppMember | undefined>(undefined);
  const mainColor = site.mainColor;
  const backgroundColor = site.backgroundColor;
  const isChat = site.isChat;
  const appId = site.sendbirdAppId;

  useEffect(() => {
    const auth = getAuth();
    auth.tenantId = site.tenantId;
    onAuthStateChanged(auth, (loginUser) => {
      setLoginUser(loginUser);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const res = await fetchMemberDetail();
        if (!res.isSuccess || !res.result) return;
        const newMember: TAppMember = {
          name: `${res.result.sei}${res.result.mei}`,
          rankName: res.result.rank,
          rankImage: res.result.rank_image,
          totalPoint: res.result.current_period_point,
          sendbirdUserAccessToken: res.result.sendbird_user_access_token,
          sendbirdUserId: res.result.sendbird_user_id,
          uid: res.result.uid
        };
        setMember(newMember);
      } catch (error) {
        console.error("Error fetching member details:", error);
      }
    };

    if (loginUser) {
      fetchMember();
    }
  }, [loginUser]);

  const colorSet = {
    "--sendbird-light-primary-500": mainColor,
    "--sendbird-light-primary-400": mainColor,
    "--sendbird-light-primary-300": mainColor,
    "--sendbird-light-primary-200": backgroundColor,
    "--sendbird-light-primary-100": backgroundColor,
  };

  if (loading) {
    return <></>;
  }

  return (
    <LoginUserContext.Provider
      value={{
        loginUser,
        setLoginUser,
        member,
        setMember,
      }}
    >
      {isChat ? (
        <SendbirdProvider
          breakpoint="576px"
          appId={appId}
          accessToken={member?.sendbirdUserAccessToken}
          userId={member?.sendbirdUserId || ""}
          isMultipleFilesMessageEnabled={true}
          colorSet={colorSet}
          stringSet={getStringSet()}
          dateLocale={ja}
        >
          <ChatProvider>{children}</ChatProvider>
        </SendbirdProvider>
      ) : (
        children
      )}
    </LoginUserContext.Provider>
  );
};
