import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { surveyLocalization } from 'survey-core';
import { jaStrings } from './surveys/localization';

interface Props {
  json: any;
  theme: any;
  onComplete: (survey: any) => void;
}

// デフォルト、日本語共に日本語の設定を追加
surveyLocalization.locales["en"] = jaStrings;
surveyLocalization.locales["ja"] = jaStrings;

// ボタンについては、survey-coreのデフォルトの英語のままだと、日本語のボタンが表示されないため、日本語に変更
const engLocale = surveyLocalization.locales.en;
engLocale.pagePrevText = '前へ';
engLocale.pageNextText = '次へ';
engLocale.completeText = '送信';
engLocale.invalidEmail = 'メールアドレスの形式で入力してください。';
const jaLocale = surveyLocalization.locales.ja;
jaLocale.pagePrevText = '前へ';
jaLocale.pageNextText = '次へ';
jaLocale.completeText = '送信';
jaLocale.invalidEmail = 'メールアドレスの形式で入力してください。';
export default function SurveyComponent({ json, theme, onComplete }: Props) {
  const model = new Model(json);
  model.locale = "ja";
  model.applyTheme(theme);

  return <Survey model={model} onComplete={onComplete} />;
}
