import axios, { AxiosRequestConfig } from "axios";
import Axios from "axios";
import aspida from "@aspida/axios";
import api from "../api/$api";
import qs from "qs";

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  paramsSerializer: (params: any) => qs.stringify(params, { indices: false }),
};

const client = (config: AxiosRequestConfig = defaultConfig) => {
  return api(aspida(axios, config));
};

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export default client;
