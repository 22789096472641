import BaseHead from "../templates/BaseHead";
import STUtils from "../../assets/styles/utility.module.scss";
import LoginBackground from "../organisms/LoginBackground";
import { getSubdomain } from "../../utils/getSubdomain";
import MemberIdentificationCard from "../organisms/MemberIdentificationCard";
import { routes } from "../../router/Router";
import {
  postMemberIdentification,
  TApiMemberIdentificationResult,
} from "../../utils/api/postMemberIdentification";
import TApiResult from "../../types/api/TApiResult";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const MemberIdentification = (): JSX.Element => {
  const subdomain = getSubdomain(window.location.hostname);
  const urlParams = useParams<{ id: string; datetimeHash: string }>();
  const [message, setMessage] = useState({
    title: "メール認証",
    text: "メール認証中",
  });

  useEffect(() => {
    const actions = async () => {
      await postMemberIdentification(urlParams.id, urlParams.datetimeHash).then(
        (res: TApiResult & TApiMemberIdentificationResult) => {
          if (res?.result?.status_code || !res?.isSuccess || res?.status !== 200) {
            setMessage({
              title: "メール認証失敗",
              text: "URLの有効期限が切れています。再度実施してください",
            });
          } else {
            setMessage({
              title: "メール認証完了",
              text: "メールの認証が完了しました。",
            });
          }
        }
      );
    };
    actions();
  }, [urlParams.datetimeHash, urlParams.id]);
  return (
    <BaseHead
      title={routes.memberIdentification.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
    >
      <LoginBackground />
      <section className={STUtils.container_large}>
        <MemberIdentificationCard message={message} />
      </section>
    </BaseHead>
  );
};

export default MemberIdentification;
