import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";
import { routes } from "../../router/Router";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { TAppInfoArticle } from "../../types/TAppInfoArticle";
import { fetchContentDetail } from "../../utils/api/fetchContentDetail";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { getSubdomain } from "../../utils/getSubdomain";
import Ogp from "../atoms/Ogp";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import VideoDetail from "../organisms/VideoDetail";
import BaseHead from "../templates/BaseHead";

const Video: React.FC = () => {
  const urlParams = useParams<{ id: string }>();
  const subdomain = getSubdomain(window.location.hostname);
  const history = useHistory();
  const auth = getAuth();
  const spFlag = CheckSpFlag();

  const [video, setVideo] = useState<TAppInfoArticle>();

  useEffect(() => {
    fetchContentDetail(urlParams.id).then((res) => {
      if (!res.result || !res.isSuccess) return history.push(routes.error.path);
      const eyeCatchImaegeUrl =
        res.result.img_url !== null
          ? res.result.img_url !== ""
            ? res.result.img_url
            : undefined
          : undefined;
      const video = {
        id: String(res.result.id),
        uid: auth.currentUser?.uid ? auth.currentUser?.uid : null,
        title: res.result.title,
        content: res.result.content,
        eyeCatchImaegeUrl: eyeCatchImaegeUrl,
        postDate: res.result.post_date,
        categoryName: res.result.category_name,
        categoryId: res.result.category_id,
        likeCount: res.result.like_count,
      };
      setVideo(video);
      postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.id]);

  if (!video) return <></>;
  return (
    <BaseHead
      hasBackButton={true}
      title={video.title}
      login={false}
      subdomain={subdomain}
      hasBackground={true}
      hasNavigationBar={true}
      customMainStyle={{
        paddingTop: spFlag ? "6vh" : "15vh",
        paddingBottom: "0px",
        backgroundColor: "var(--sub-color)",
      }}
    >
      <Ogp
        path={routes.informationDetail.path(video.id)}
        imageUrl={video.eyeCatchImaegeUrl}
      />
      <VideoDetail video={video} spFlag={spFlag} />
      <div>
        <BottomNavigationBar />
      </div>
    </BaseHead>
  );
};

export default Video;
