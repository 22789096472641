import EditUserProfile from "@sendbird/uikit-react/EditUserProfile";
import GroupChannelHeader from "@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader";
import MessageInputWrapper from "@sendbird/uikit-react/GroupChannel/components/MessageInputWrapper";
import { GroupChannelProvider } from "@sendbird/uikit-react/GroupChannel/context";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import GroupChannelListHeader from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader";
import React, { useContext, useRef, useState } from "react";
import { UnreadMessageContext } from "../../providers/ChatProvider";
import { SiteContext } from "../../providers/SiteProvider";
import Icon from "@sendbird/uikit-react/ui/Icon";
import MessageSearch from "@sendbird/uikit-react/MessageSearch";
import GroupChannelUI from "@sendbird/uikit-react/GroupChannel/components/GroupChannelUI";
import MessageContent from "@sendbird/uikit-react/ui/MessageContent";
import ChatCustomSeparator from "../molecules/ChatCustomSeparator";
import { GroupChannelListItem } from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListItem';
import { RenderCustomSeparatorProps } from "@sendbird/uikit-react/types/types";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";
import ChannelSettingsUI from "@sendbird/uikit-react/ChannelSettings/components/ChannelSettingsUI";
import { Box } from "@material-ui/core";
import { ChatCustomModerationPanel } from "./ChatCustomModerationPanel";
import { LoginUserContext } from '../../providers/LoginUserProvider';

type Props = {
  channel: any;
  setChannel: Function;
  sendMailMessage: Function;
  fileMessageParams: any;
  setFileMessageParams: Function;
  setOpen: Function;
  handleFileChange: Function;
};

const PANELS = {
  CHANNEL_LIST: "CHANNEL_LIST",
  CHANNEL: "CHANNEL",
  CHANNEL_SETTINGS: "CHANNEL_SETTINGS",
  CHANNEL_LIST_EDIT: "CHANNEL_LIST_EDIT",
  CHANNEL_SEARCH: "CHANNEL_SEARCH",
};

const ChatMobile = ({ channel, setChannel, sendMailMessage, fileMessageParams,setFileMessageParams, setOpen, handleFileChange}: Props) => {
  const [panel, setPanel] = useState(PANELS.CHANNEL_LIST);
  const { updateUnreadCount } = useContext(UnreadMessageContext);
  const groupChatTextHeader = useContext(SiteContext).groupChatTextHeader;
  const {member} = useContext(LoginUserContext);
  const fileRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div
        style={{
          height: "calc(100dvh - 150px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {panel === PANELS.CHANNEL_LIST && (
          <GroupChannelList
            key={new Date().toString()}
            allowProfileEdit={true}
            renderUserProfile={() => <EditUserProfile />}
            selectedChannelUrl={channel ? channel.url : ""}
            onChannelSelect={(channel: any) => {
              updateUnreadCount(channel.url, 0);
              setChannel(channel);
              setPanel(PANELS.CHANNEL);
            }}
            onChannelCreated={(channel: any) => {
              setChannel(channel);
              setPanel(PANELS.CHANNEL);
            }}
            renderHeader={() => (
              <GroupChannelListHeader
                onEdit={() => setPanel(PANELS.CHANNEL_LIST_EDIT)}
              />
            )}
            disableAutoSelect={true}
            renderChannelPreview={(channel: any) => {
              const memberIdInChannel = channel?.channel?.members?.map(
                (member: any) => member.userId
              );
              return memberIdInChannel.includes(member?.sendbirdUserId) ? (
                <GroupChannelListItem
                  tabIndex={channel?.tabIndex}
                  channel={channel?.channel}
                  onClick={channel?.onClick}
                  renderChannelAction={channel?.renderChannelAction}
                />
              ) : (
                <></>
              );
            }}
            channelListQueryParams={{ includeEmpty: true }}
          />
        )}
        {panel === PANELS.CHANNEL && channel && (
          <GroupChannelProvider
            key={channel.url}
            onBeforeSendMultipleFilesMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendFileMessage={(fileMessageCreateParams) => {
              setFileMessageParams(fileMessageCreateParams);
              setOpen(true);
              return fileMessageParams;
            }}
            onBeforeSendUserMessage={(message) => {
              sendMailMessage();
              return message;
            }}
            channelUrl={channel.url}
            onBackClick={() => setPanel(PANELS.CHANNEL_LIST)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <GroupChannelUI
                renderChannelHeader={() => (
                  <>
                    <GroupChannelHeader
                      renderRight={() => (
                        <>
                          {/* 日本語での検索が可能になってからコメントアウト解除 */}
                          {/* <Icon type='SEARCH' fillColor='PRIMARY' onClick={() => {setPanel(PANELS.CHANNEL_SEARCH)}}/> */}
                          <Icon
                            type="INFO"
                            fillColor="PRIMARY"
                            onClick={() => {
                              setPanel(PANELS.CHANNEL_SETTINGS);
                            }}
                          />
                        </>
                      )}
                    />
                    <div
                      style={{
                        fontSize: "12px",
                        border: "1px solid #ccc",
                        textAlign: "center",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {groupChatTextHeader}
                    </div>
                  </>
                )}
                renderMessageContent={(contentProps: any) => {
                  if (contentProps?.message?.message) {
                    if (contentProps?.message?.message.trim().length === 0) {
                      return <></>;
                    }
                  }
                  return <MessageContent {...contentProps} />;
                }}
                renderMessageInput={() => (
                  <MessageInputWrapper 
                      renderFileUploadIcon={() => (
                        <>
                          <Icon
                            type="ATTACH"
                            fillColor="PRIMARY"
                            onClick={() => fileRef.current?.click()}
                          />
                          <input 
                            type="file" 
                            multiple 
                            onChange={(e) => handleFileChange(e, fileRef)}
                            ref={fileRef}
                            style={{display: 'none'}}
                          />
                        </>
                      )}
                    />
                )}
                renderCustomSeparator={(props: RenderCustomSeparatorProps) => (
                  <ChatCustomSeparator timestamp={props.message.createdAt} />
                )}
              />
            </div>
          </GroupChannelProvider>
        )}
        {panel === PANELS.CHANNEL_SETTINGS && channel && (
          <ChannelSettingsProvider
            channelUrl={channel.url}
            onCloseClick={() => setPanel(PANELS.CHANNEL)}
          >
            <ChannelSettingsUI
              renderChannelProfile={() => <Box />}
              renderModerationPanel={() => (
                <ChatCustomModerationPanel
                  channelMembersList={channel.members}
                />
              )}
            />
          </ChannelSettingsProvider>
        )}
        {panel === PANELS.CHANNEL_LIST_EDIT && (
          <EditUserProfile
            onCancel={() => setPanel(PANELS.CHANNEL_LIST)}
            onEditProfile={() => setPanel(PANELS.CHANNEL_LIST)}
            onThemeChange={() => null}
          />
        )}
        {panel === PANELS.CHANNEL_SEARCH && (
          <MessageSearch
            channelUrl={channel.url}
            onCloseClick={() => setPanel(PANELS.CHANNEL)}
          />
        )}
      </div>
    </>
  );
};

export default ChatMobile;
