import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { NaviItem } from "../../types/naviItem";
import STBottomNav from "../../assets/styles/organisms/footer.module.scss";
import { Link, makeStyles } from "@material-ui/core";
import { UnreadMessageContext } from "../../providers/ChatProvider";
import { SiteContext } from "../../providers/SiteProvider";

const useStyles = makeStyles(() => ({
  icon: {
    minWidth: "18px",
    minHeight: "18px",
    maxWidth: "24px",
    maxHeight: "24px",
  },
}));
const BottomNavigationItem: React.FC<NaviItem> = (props: NaviItem) => {
  const classes = useStyles();
  const location = useLocation();
  const { unreadCount } = useContext(UnreadMessageContext);
  const isChat = useContext(SiteContext).isChat;
  const searchParams = new URLSearchParams(location.search);

  return (
    <>
      {props.url.match(/^https?:\/\//) ? (
        <Link
          className={STBottomNav.nav_item}
          color="secondary"
          href={props.url}
          target="_blank"
        >
          <img src={props.icon} alt={props.name} className={classes.icon} />
          <h5>{props.name}</h5>
        </Link>
      ) : (
        <NavLink
          to={props.url}
          exact
          className={(isActive) =>
            STBottomNav.nav_item +
            " " +
            (isActive && !searchParams.has("list")
              ? STBottomNav.nav_item_active
              : STBottomNav.nav_item_inactive)
          }
        >
          <img src={props.icon} alt={props.name} className={classes.icon} />
          <h5>{props.name}</h5>
          {props.url === "/chat" && unreadCount > 0 && isChat && (
            <span className={STBottomNav.chat_notification}></span>
          )}
        </NavLink>
      )}
    </>
  );
};

export default BottomNavigationItem;
