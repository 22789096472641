import * as yup from "yup";
import { TAppPrefectureNames } from "../../types/TAppPrefectureNames";
import { TMemberRegistrationFormSettingResponse } from "../../api/member_registration_form_setting";
import {
  ERROR_BIRTHDAY_DATE,
  ERROR_ENTRY_FORM_ADDRESS_FORMAT,
  ERROR_ENTRY_FORM_CITY_NAME_FORMAT,
  ERROR_ENTRY_FORM_HANKAKU_NUMBER_FORMAT,
  ERROR_ENTRY_FORM_HIRAGANA_FORMAT,
  ERROR_ENTRY_FORM_INSTAGRAM_FORMAT,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS2,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS3,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_FIRSTNAME,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_LASTNAME,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME_KANA,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_INSTAGRAM,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME_KANA,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_PASSWORD,
  ERROR_ENTRY_FORM_LENGTH_LIMIT_PHONE_NUMBER,
  ERROR_ENTRY_FORM_MISMATCHED_PASSWORD,
  ERROR_ENTRY_FORM_NAME_FORMAT,
  ERROR_ENTRY_FORM_PASSWORD_FORMAT,
  ERROR_ENTRY_FORM_POST_CODE_FORMAT,
  ERROR_ENTRY_FORM_REQURIRED_ADDRESS1,
  ERROR_ENTRY_FORM_REQURIRED_ADDRESS2,
  ERROR_ENTRY_FORM_REQURIRED_ADDRESS3,
  ERROR_ENTRY_FORM_REQURIRED_APPLY,
  ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_FIRSTNAME,
  ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_LASTNAME,
  ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME,
  ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME_KANA,
  ERROR_ENTRY_FORM_REQURIRED_INSTAGRAM,
  ERROR_ENTRY_FORM_REQURIRED_LASTNAME,
  ERROR_ENTRY_FORM_REQURIRED_LASTNAME_KANA,
  ERROR_ENTRY_FORM_REQURIRED_PASSWORD,
  ERROR_ENTRY_FORM_REQURIRED_PASSWORDCONFIRM,
  ERROR_ENTRY_FORM_REQURIRED_PHONE_NUMBER,
  ERROR_ENTRY_FORM_REQURIRED_EMAIL,
  ERROR_ENTRY_FORM_REQURIRED_POST_CODE,
  ERROR_ENTRY_FORM_SPACE_FORMAT,
  ERROR_ENTRY_FORM_EMAIL_FORMAT,
} from "../messages";
import CONSTANTS from "../constants";

const createStringSchema = (
  isRequired: boolean,
  isRequiredMsg: string,
  regexMsg: string,
  regex: {
    test: (
      arg0: string
    ) =>
      | boolean
      | void
      | yup.ValidationError
      | Promise<boolean | yup.ValidationError>;
  },
  maxLength: number | yup.Reference<number>,
  maxLengthMsg: string,
  spaceRegex = /^[^\s]*$/
) => {
  return yup
    .string()
    .trim()
    .when([], {
      is: () => isRequired,
      then: (schema) => schema.required(isRequiredMsg),
      otherwise: (schema) => schema.notRequired().nullable(),
    })
    .test("is-valid", regexMsg, (value) => (value ? regex.test(value) : true))
    .test("is-valid-space", ERROR_ENTRY_FORM_SPACE_FORMAT, (value) =>
      value ? spaceRegex.test(value) : true
    )
    .max(maxLength, maxLengthMsg);
};

const buildValidationSchema = (
  settings: TMemberRegistrationFormSettingResponse
) => {
  const schemaFields: any = {};

  settings.forEach((field) => {
    const isRequired = field.is_required;

    switch (field.physical_name) {
      case "sei_mei":
        schemaFields.sei = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME,
          ERROR_ENTRY_FORM_NAME_FORMAT,
          /^[ぁ-ゞァ-ヾ一-龥々-〇仝a-zA-Z]*$/,
          64,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME
        );
        schemaFields.mei = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_LASTNAME,
          ERROR_ENTRY_FORM_NAME_FORMAT,
          /^[ぁ-ゞァ-ヾ一-龥々-〇仝a-zA-Z]*$/,
          64,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME
        );
        break;
      case "kana_sei_mei":
        schemaFields.kanaSei = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_FIRSTNAME_KANA,
          ERROR_ENTRY_FORM_HIRAGANA_FORMAT,
          /^[ぁ-ゞー・]*$/,
          64,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_FIRSTNAME_KANA
        );
        schemaFields.kanaMei = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_LASTNAME_KANA,
          ERROR_ENTRY_FORM_HIRAGANA_FORMAT,
          /^[ぁ-ゞー・]*$/,
          64,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_LASTNAME_KANA
        );
        break;

      case "contractor_sei_mei":
        const namePattern = /^[ぁ-ゞァ-ヾ一-龥々-〇仝a-zA-Z\s]*$/;
        const maxLength = 64;

        schemaFields.contractorSei = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema
                .required(ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_FIRSTNAME)
                .matches(namePattern, ERROR_ENTRY_FORM_NAME_FORMAT)
                .max(
                  maxLength,
                  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_FIRSTNAME
                ),
            otherwise: (schema) =>
              schema
                .nullable()
                .test(
                  "require-if-other-filled",
                  ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_FIRSTNAME,
                  function (value) {
                    const { contractorSei, contractorMei } = this.parent;
                    return !contractorMei || !!value;
                  }
                )
                .test(
                  "format-if-filled",
                  ERROR_ENTRY_FORM_NAME_FORMAT,
                  (value) => !value || namePattern.test(value)
                )
                .max(
                  maxLength,
                  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_FIRSTNAME
                ),
          });

        schemaFields.contractorMei = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema
                .required(ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_LASTNAME)
                .matches(namePattern, ERROR_ENTRY_FORM_NAME_FORMAT)
                .max(
                  maxLength,
                  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_LASTNAME
                ),
            otherwise: (schema) =>
              schema
                .nullable()
                .test(
                  "require-if-other-filled",
                  ERROR_ENTRY_FORM_REQURIRED_CONTRACTOR_LASTNAME,
                  function (value) {
                    const { contractorSei, contractorMei } = this.parent;
                    return !contractorSei || !!value;
                  }
                )
                .test(
                  "format-if-filled",
                  ERROR_ENTRY_FORM_NAME_FORMAT,
                  (value) => !value || namePattern.test(value)
                )
                .max(
                  maxLength,
                  ERROR_ENTRY_FORM_LENGTH_LIMIT_CONTRACTOR_LASTNAME
                ),
          });
        break;
      case "email":
        schemaFields.email = yup
          .string()
          .when([], {
            is: () => isRequired,
            then: (schema) => schema.required(ERROR_ENTRY_FORM_REQURIRED_EMAIL),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .test("is-valid-email", ERROR_ENTRY_FORM_EMAIL_FORMAT, (value) => {
            if (value) {
              return CONSTANTS.PATTERN.EMAIL.test(value);
            }
            return true;
          });
        break;

      case "tel":
        schemaFields.tel = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema.required(ERROR_ENTRY_FORM_REQURIRED_PHONE_NUMBER),
            otherwise: (schema) => schema,
          })
          .test(
            "is-valid-tel",
            ERROR_ENTRY_FORM_HANKAKU_NUMBER_FORMAT,
            (value) => {
              if (value) {
                return /^[0-9]+$/.test(value);
              }
              return true;
            }
          )
          .test(
            "is-valid-length",
            ERROR_ENTRY_FORM_LENGTH_LIMIT_PHONE_NUMBER,
            (value) => {
              if (value) {
                return value.length === 10 || value.length === 11;
              }
              return true;
            }
          )
          .nullable();
        break;

      case "zipcode":
        schemaFields.zipcode = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema.required(ERROR_ENTRY_FORM_REQURIRED_POST_CODE),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .test(
            "is-valid-zipcode",
            ERROR_ENTRY_FORM_POST_CODE_FORMAT,
            (value) => {
              if (value) {
                return /^\d{3}-?\d{4}$/.test(value);
              }
              return true;
            }
          );
        break;

      case "pref_name":
        schemaFields.prefName = yup.string().when([], {
          is: () => isRequired,
          then: (schema) =>
            schema
              .required(ERROR_ENTRY_FORM_REQURIRED_ADDRESS1)
              .oneOf(TAppPrefectureNames, ERROR_ENTRY_FORM_REQURIRED_ADDRESS1),
          otherwise: (schema) => schema.notRequired().nullable(),
        });
        break;
      case "city_name":
        schemaFields.cityName = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_ADDRESS2,
          ERROR_ENTRY_FORM_CITY_NAME_FORMAT,
          /^[ぁ-ゞァ-ヾ一-龥々-〇仝\s]*$/,
          32,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS2
        );
        break;
      case "address":
        schemaFields.address = yup
          .string()
          .nullable()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema.required(ERROR_ENTRY_FORM_REQURIRED_ADDRESS3),
            otherwise: (schema) =>
              schema.notRequired().when("address", {
                is: (value: any) => !!value,
                then: (schema) =>
                  schema
                    .min(3, ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS3)
                    .max(255, ERROR_ENTRY_FORM_LENGTH_LIMIT_ADDRESS3),
              }),
          })
          .trim()
          .test(
            "is-valid-address",
            ERROR_ENTRY_FORM_ADDRESS_FORMAT,
            (value) => {
              if (value) {
                return /^[ぁ-ゞァ-ヾ一-龥々-〇仝0-9a-zA-Z０-９ａ-ｚＡ-Ｚ-－ー−–ｰ\s]*$/.test(
                  value
                );
              }
              return true;
            }
          );
        break;
      case "instagram_ownerinput":
        schemaFields.instagramOwnerinput = createStringSchema(
          isRequired,
          ERROR_ENTRY_FORM_REQURIRED_INSTAGRAM,
          ERROR_ENTRY_FORM_INSTAGRAM_FORMAT,
          /^[a-zA-Z0-9._]*$/,
          30,
          ERROR_ENTRY_FORM_LENGTH_LIMIT_INSTAGRAM
        );
        break;
      case "birthday":
        schemaFields.birthday = yup
          .object()
          .shape({
            year: yup.string().when([], {
              is: () => isRequired,
              then: (schema) => schema.required(ERROR_BIRTHDAY_DATE),
              otherwise: (schema) => schema.notRequired(),
            }),
            month: yup.string().when([], {
              is: () => isRequired,
              then: (schema) => schema.required(ERROR_BIRTHDAY_DATE),
              otherwise: (schema) => schema.notRequired(),
            }),
            day: yup.string().when([], {
              is: () => isRequired,
              then: (schema) => schema.required(ERROR_BIRTHDAY_DATE),
              otherwise: (schema) => schema.notRequired(),
            }),
          })
          .test("is-valid-birthday", ERROR_BIRTHDAY_DATE, (value) => {
            if (value && (value.year || value.month || value.day)) {
              return !!(value.year && value.month && value.day);
            }
            return true;
          })
          .when([], {
            is: () => isRequired,
            then: (schema) => schema.required(ERROR_BIRTHDAY_DATE),
            otherwise: (schema) => schema.notRequired(),
          });
        break;
      case "password":
        schemaFields.password = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema.required(ERROR_ENTRY_FORM_REQURIRED_PASSWORD),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .test(
            "is-valid-password",
            ERROR_ENTRY_FORM_PASSWORD_FORMAT,
            (value) => {
              if (value) {
                return /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9!-~]*$/.test(value);
              }
              return true;
            }
          )
          .min(8, ERROR_ENTRY_FORM_LENGTH_LIMIT_PASSWORD)
          .max(20, ERROR_ENTRY_FORM_LENGTH_LIMIT_PASSWORD);

        schemaFields.passwordConfirm = yup
          .string()
          .trim()
          .when([], {
            is: () => isRequired,
            then: (schema) =>
              schema.required(ERROR_ENTRY_FORM_REQURIRED_PASSWORDCONFIRM),
            otherwise: (schema) => schema.notRequired().nullable(),
          })
          .test(
            "confirmPassword",
            ERROR_ENTRY_FORM_MISMATCHED_PASSWORD,
            function (passwordConfirm, context) {
              if (passwordConfirm && passwordConfirm?.length > 0) {
                return context.parent.password === passwordConfirm;
              }
              return true;
            }
          );
        break;

      default:
        break;
    }
  });

  schemaFields.apply = yup
    .boolean()
    .oneOf([true], ERROR_ENTRY_FORM_REQURIRED_APPLY)
    .required(ERROR_ENTRY_FORM_REQURIRED_APPLY);

  return yup.object().shape(schemaFields);
};

export default buildValidationSchema;
