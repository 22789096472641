import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import STGoodButton from "../assets/styles/atoms/goodButton.module.scss";

type Props = {
  isClicked: boolean;
  count: number;
  hundleClick: (isClicked: boolean) => void;
};

const GoodButton: React.FC<Props> = (props: Props) => {
  return (
    <div className={STGoodButton.container}>
      <div className={STGoodButton.btn}>
        <div
          className={STGoodButton.icon_container}
          onClick={() => props.hundleClick(!props.isClicked)}
        >
          {props.isClicked ? (
            <FavoriteIcon
              style={{ width: "16px", height: "16px" }}
              className={STGoodButton.liked}
            />
          ) : (
            <FavoriteBorderIcon
              style={{ width: "16px", height: "16px" }}
              className={STGoodButton.liked}
            />
          )}
        </div>
        <div className={STGoodButton.counter}>{props.count}</div>
      </div>
      <div className={STGoodButton.action_label}>いいね</div>
    </div>
  );
};

export default GoodButton;
