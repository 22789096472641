import React, { useContext, useEffect, useState } from "react";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import BaseHead from "../templates/BaseHead";
import { getSubdomain } from "../../utils/getSubdomain";
import "../../assets/styles/sendbird.css";
import ChatPc from "../organisms/ChatPc";
import ChatMobile from "../organisms/ChatMobile";
import { useHistory } from "react-router-dom";
import { routes } from "../../router/Router";
import { SiteContext } from "../../providers/SiteProvider";
import { postMailMessage } from "../../utils/api/postMailMessage";
import { LoginUserContext } from "../../providers/LoginUserProvider";
import { TApiMemberDetailResult, fetchMemberDetail } from "../../utils/api/fetchMemberDetail";
import TApiResult from "../../types/api/TApiResult";
import { TAppMember } from "../../types/TAppMember";
import ComfirmFilesMessageModal from "../organisms/ComfirmFilesMessageModal";
import { fetchChatConfig } from "../../utils/api/fetchChatConfig";
import { CircularProgress } from "@material-ui/core";

const Chat = () => {
  const [channel, setChannel] = useState<any>(null);
  const subdomain = getSubdomain(window.location.hostname);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 799);
  const context = useContext(SiteContext);
  const client = useContext(LoginUserContext);
  const [fileMessageParams, setFileMessageParams] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const updateMedia = () => {
    setIsMobile(window.innerWidth < 799);
  };

  const handleClose = () => {
    setOpen(false);
    setFileMessageParams(null);
  };

  const isImage = (file: any) => {
    return file.type.includes('image/');
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fileRef: React.RefObject<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    const fileList = Array.from(files).map((file) => ({
      file,
      name: file.name,
      size: file.size,
      type: file.type,
    }));
    setFileMessageParams({ fileInfoList: fileList });
    if (fileRef.current) {
      fileRef.current.value = '';
    }
    setOpen(true);
  };

  const handleAgree = () => {
    setOpen(false);
    if (fileMessageParams) {
      if (fileMessageParams?.fileInfoList) {
        const { fileInfoList } = fileMessageParams;
        const images = fileInfoList.filter(isImage);
        const others = fileInfoList.filter((file: any) => !isImage(file));
        if(images.length > 1){
          channel.sendMultipleFilesMessage({
            fileInfoList: images
          });
        } else if(images.length === 1){
          channel.sendFileMessage(images[0]);
        }
        if(others.length > 0){
          Promise.all(others.map((other: any) => (
            channel.sendFileMessage(other)
          )))
        }
        sendMailMessage();
        setFileMessageParams(null);
      }
    }
  };

  const sendMailMessage = () => {
      const userIds: Array<number> = [];
      const memberIds: Array<number> = [];
      let checkCompany = false;
      channel.members.forEach((mem: any) => {
          if(mem.userId.startsWith('user')){
              userIds.push(Number(mem.userId.split('-')[1]))
          } else if(mem.userId.startsWith('member') && mem.userId !== client.member?.sendbirdUserId){
              memberIds.push(Number(mem.userId.split('-')[1]))
          } else if(mem.userId.startsWith('company')){
              checkCompany = true;
          }
      })
      postMailMessage({
          company_id: context.contractCompanyId,
          user_ids: userIds,
          member_ids: memberIds,
          site_emails: checkCompany ? context.email.trim().split(',') : [],
          site_allow_chat_mail_sending: context.siteAllowChatMailSending
      })
  }

  useEffect(() => {
    fetchChatConfig().then((res: any) => {
      if (!res.isSuccess || !res.result) return;
      if (!res.result[0].is_active) {
        return history.push(routes.chatOff.path);
      }
      setIsLoading(false);
    })

    fetchMemberDetail().then((res: TApiResult & TApiMemberDetailResult) => {
      if (res.isSuccess && res?.result) {
        const newMember: TAppMember = {
          name: `${res.result.sei}${res.result.mei}`,
          rankName: res.result.rank,
          rankImage: res.result.rank_image,
          totalPoint: res.result.current_period_point,
          email: res.result.email,
          sendbirdUserId: res.result.sendbird_user_id,
          sendbirdUserAccessToken: res.result.sendbird_user_access_token
        };
        client.setMember(newMember);
      }
    });
    window.addEventListener("resize", updateMedia);
    return () => {
        window.removeEventListener("resize", updateMedia);
    };
  }, []);

  return (
    <BaseHead
      title={"チャット"}
      login={true}
      subdomain={subdomain}
      hasBackground
      hasNavigationBar={true}
      showFooter={isMobile ? false : true}
    >
      {isLoading ? (
        <CircularProgress />
      ) : isMobile ? (
        <ChatMobile
          setOpen={setOpen}
          fileMessageParams={fileMessageParams}
          setFileMessageParams={setFileMessageParams}
          handleFileChange={handleFileChange}
          channel={channel}
          setChannel={setChannel}
          sendMailMessage={sendMailMessage}
        />
      ) : (
        <ChatPc
          setOpen={setOpen}
          fileMessageParams={fileMessageParams}
          setFileMessageParams={setFileMessageParams}
          handleFileChange={handleFileChange}
          channel={channel}
          setChannel={setChannel}
          sendMailMessage={sendMailMessage}
        />
      )}
      <div style={{ marginBottom: "24px" }}>
        <BottomNavigationBar />
      </div>
      {fileMessageParams && (
        <ComfirmFilesMessageModal
          open={open}
          handleClose={handleClose}
          handleAgree={handleAgree}
          fileMessageParams={fileMessageParams}
          setFileMessageParams={setFileMessageParams}
        />
      )}
    </BaseHead>
  );
};

export default Chat;
