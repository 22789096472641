import { Link } from "react-router-dom";
import Image from "../atoms/Image";
import Time from "../atoms/Time";

import { useEffect, useState } from "react";
import noImage from "../../assets/images/noImage-PC.png";
import STArticleCard from "../../assets/styles/molecules/articleCard.module.scss";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { format } from "../../types/api/TApiDatetime";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import setEmbedScript from "../../utils/setEmbedScript";
import GoodIcon from "../atoms/GoodIcon";
import playButtonImage from "../atoms/play_video_button.png";
import VideoModal from "../organisms/VideoModal";
import LikeCard from "./LikeCard";

type Props = {
  title: string;
  className: string;
  linkUrl: string;
  thumbnailUrl: string | null;
  thumbnailAlt: string;
  createdAt: string;
  goodCount: number;
  contentTypeId?: number;
  content?: string;
  url?: string;
  isRecommended?: boolean;
};

const ArticleCard: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => {
    setIsModalOpen(true);
    const videoUrl = window.location.origin + props.linkUrl;
    postMemberAccessLog(ACCESS_LOG_ACTION.contentDetail, document.referrer, videoUrl);
  };
  const handleModalClose = () => setIsModalOpen(false);

  const isVideo = props.contentTypeId === 5;
  const renderVideo = (props: Props) => {
    return (
      <div className={STArticleCard.videoWrapper} onClick={handleModalOpen}>
        <img
          src={playButtonImage}
          alt="再生ボタン"
          className={STArticleCard.play_button}
        />
        <div
          style={{ backgroundImage: `url(${props.thumbnailUrl || noImage})` }}
          className={STArticleCard.article_card_video_thumbnail_img}
        ></div>
      </div>
    );
  };
  const TikTokEmbed: React.FC<{ content: string }> = ({ content }) => {
    useEffect(() => {
      setEmbedScript("TikTok", content);
    }, [content]);

    return (
      <EmbedContainer>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </EmbedContainer>
    );
  };

  const InstagramEmbed: React.FC<{ content: string }> = ({ content }) => {
    useEffect(() => {
      setEmbedScript("Instagram", content);
    }, [content]);
    return (
      <EmbedContainer>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </EmbedContainer>
    );
  };

  const EmbedContainer: React.FC = ({ children }) => (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
  const getEmbedComponent = (content: string | undefined) => {
    if (!content) return null;

    if (content.includes("tiktok.com")) {
      return <TikTokEmbed content={content} />;
    } else if (content.includes("instagram.com")) {
      return <InstagramEmbed content={content} />;
    } else {
      return (
        <EmbedContainer>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </EmbedContainer>
      );
    }
  };

  const EmbedComponent = getEmbedComponent(props.content);
  return (
    <article className={props.className}>
      {isModalOpen && (
        <VideoModal
          EmbedComponent={EmbedComponent}
          isModalOpen={isModalOpen}
          onClose={handleModalClose}
        />
      )}
      <div className={STArticleCard.article_card_thumbnail}>
        {isVideo ? (
          renderVideo(props)
        ) : (
          <Link to={props.linkUrl} className={props.isRecommended ? STArticleCard.img : ''}>
            <Image
              alt={props.thumbnailAlt}
              imageUrl={props.thumbnailUrl || noImage}
              className={props.isRecommended ? STArticleCard.article_card_thumbnail_img_new : STArticleCard.article_card_thumbnail_img}
              shownDefault={!props.thumbnailUrl}
            />
            {
              props.isRecommended && 
              <div className={STArticleCard.likeCardContainer}>
                <LikeCard count={props.goodCount}/>
              </div>
            }
          </Link>
        )}
      </div>

      { (!props.isRecommended) &&
        <div className={STArticleCard.article_card_article_txt}>
          <Link
            to={props.linkUrl}
            className={STArticleCard.article_card_article_title}
            onClick={(e) => {
              if (isVideo) {
                e.preventDefault();
                e.stopPropagation();
                handleModalOpen();
              }
            }}
          >
            {props.title}
          </Link>
          <div className={STArticleCard.article_card_created_goodcount}>
            <Time
              className={STArticleCard.article_card_article_time}
              time={format(props.createdAt, "yyyy.MM.dd")}
            />
            {!isVideo && (
              <div className={STArticleCard.good_count}>
                <GoodIcon count={props.goodCount} />
              </div>
            )}
          </div>
        </div>
      }
    </article>
  );
};
export default ArticleCard;
