import Drawer from "@material-ui/core/Drawer";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import STSearchForm from "../../assets/styles/molecules/searchForm.module.scss";
import STDrawerMenu from "../../assets/styles/organisms/drawerMenu.module.scss";
import STMemberInfoCard from "../../assets/styles/organisms/memberInfoCard.module.scss";
import PAGE_CONFIG from "../../config/page";
import { SiteContext } from "../../providers/SiteProvider";
import { routes } from "../../router/Router";
import { NaviItem } from "../../types/naviItem";
import CONSTANTS from "../../utils/constants";
import Button from "../atoms/Button";
import ButtonLink from "../atoms/ButtonLink";
import DrawerMenuButton from "../atoms/DrawerMenuButton";
import Text from "../atoms/form/Text";
import SvgSearch from "../atoms/icons/Search";
import Logo from "../molecules/ImageLink";
import NaviMenu from "../molecules/NaviMenu";
import MemberInfoCardNew from "./MemberInforCardNew";


type Props = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  subdomain: string;
  imageUrl: string;
  naviItems: NaviItem[];
  showArrowIcon: boolean | undefined
};

const DrawerMenu: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const hundleClick = () => {
    props.setIsOpen(!props.isOpen);
  };

  const site = useContext(SiteContext);
  const isChat = site?.isChat;
  const [query , setQuery] = useState<string>('');

  const handleChange = (value: string) => {
    if (value && value.length > CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT) {
      setQuery(value.substring(0, CONSTANTS.MAX_LENGTH.SEARCH_FORM_TEXT));
      return;
    }
    setQuery(value);
  }

  const handleSearch = () => {
    hundleClick()
    history.push(`${routes.informations.path}?title=${query}`);
  }

  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={hundleClick}
      classes={{ paper: STDrawerMenu.drawer_menu }}
    >
      <div className={STDrawerMenu.header_new}>
        <div style={{display: "flex", alignItems: "center"}}>
          <Logo
            linkUrl={routes.top.path}
            imageUrl={props.imageUrl}
            className={STDrawerMenu.logo_new}
            alt={props.subdomain}
          />
        </div>
        <DrawerMenuButton
          isOpen={props.isOpen}
          setIsOpen={hundleClick}
          subdomain={props.subdomain}
        />
      </div>
      <div className={STDrawerMenu.navi}>
        <NaviMenu naviItems={props.naviItems} showArrowIcon = {true} className="" />
          <>
            <div className={STSearchForm.form_navigation}>
              <Text
                  className={STSearchForm.input_new}
                  name='q'
                  placeholder='サイト内検索'
                  onChange={handleChange}
                />
              <Button
                className={`${STSearchForm.button_new} ${PAGE_CONFIG.STATIC_CLASSES.NAVI_ICON}`}
                buttonClassName={STSearchForm.button_inner}
                clickAction={handleSearch}
                text={
                  <>
                    <SvgSearch/>
                  </>
                }
              />
            </div>
            <ButtonLink
              url={isChat ? routes.chat.path : routes.contact.path}
              text={isChat ? 'チャット' : 'お問い合わせ'}
              buttonClassName={`${STDrawerMenu.member_point_request_btn_header}`}
              linkClassName={STMemberInfoCard.member_point_request_link}
            />
            <MemberInfoCardNew inHeader={true} />
          </>
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
