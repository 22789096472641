import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import STInfoArticleCardList from "../../assets/styles/organisms/infoArticleCardList.module.scss";
import STInfoArticleList from "../../assets/styles/organisms/infoArticleList.module.scss";
import PAGE_CONFIG from "../../config/page";
import { CheckSpFlag } from "../../hooks/CheckSpFlag";
import { routes } from "../../router/Router";
import { CONTENT_TYPE, TAppContentType } from "../../types/TAppContentType";
import TPager from "../../types/TAppPager";
import TApiResult from "../../types/api/TApiResult";
import { contentSummary } from "../../types/contentSummary";
import {
  TApiCategoryResult,
  fetchCategory,
} from "../../utils/api/fetchCategory";
import {
  TApiContentsResult,
  fetchContents,
} from "../../utils/api/fetchContents";
import Pagenation from "../molecules/AppPagenation";
import ArticleCard from "../molecules/ArticleCard";
import { NewsCard } from "../molecules/NewsCard";
import SpContentCard from "../molecules/SpContentCard";
import { SpNewsCard } from "../molecules/SpNewsCard";

type Props = {
  requestPage: number;
  requestCategory?: number;
  requestTitle?: string;
  requestCategorySlug?: string;
  contentTypeIds: TAppContentType[];
};

const InfoArticleList: React.FC<Props> = ({
  requestPage,
  requestCategory,
  requestTitle,
  requestCategorySlug,
  contentTypeIds,
}) => {
  const spFlag = CheckSpFlag();
  const limitCount = spFlag ? 10 : 12; // 画面幅によって取得件数を変更
  const [pager, setPager] = useState<TPager>(
    new TPager(requestPage, 0, limitCount)
  );
  const [title, setTitle] = useState<string>((): string => {
    if (requestCategory) return ``;
    if (requestCategorySlug)
      return `${requestCategorySlug}${
        "一覧"
      }`;
    if (requestTitle)
      return `${requestTitle}${ "一覧"}`;
    if (
      contentTypeIds.length === 1 &&
      contentTypeIds[0] === CONTENT_TYPE.video
    ) {
      return "動画一覧";
    }
    return `コンテンツ一覧`;
  });
  const [notFound, setNotFound] = useState<boolean>(false);
  const [contents, setContents] = useState<contentSummary[]>([]);
  const history = useHistory();
  const [displayedContents, setDisplayedContents] = useState<contentSummary[]>(
    contents.slice(0, limitCount)
  );
  const [nextIndex, setNextIndex] = useState(limitCount);
  const [currentPage, setCurrentPage] = useState(requestPage);
  const [totalContentsCount, setTotalContentsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const mapContentToSummary = (content: any): contentSummary => ({
    id: content.id,
    title: content.title,
    createdAt: content.post_date,
    contentTypeId: content.content_type_id,
    linkUrl:
      content.content_type_id === 5
        ? routes.videoDetail.path(`${content.id}`)
        : routes.informationDetail.path(`${content.id}`),
    imageUrl: content.img_url,
    content: content.content,
    goodCount: content.like_count,
    categoryId: content.category_id,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [requestPage, requestCategory, requestTitle, requestCategorySlug]);

  useEffect(() => {
    if (!requestCategory) return;

    fetchCategory(`${requestCategory}`).then(
      (res: TApiResult & TApiCategoryResult) => {
        if (!res.isSuccess || !res.result)
          return history.push(routes.error.path);
        const newTitle = `${res.result.name}${
          "一覧"
        }`;
        if (
          contentTypeIds.length === 1 &&
          contentTypeIds[0] === CONTENT_TYPE.video
        ) {
          setTitle("動画一覧");
        } else {
          setTitle(newTitle);
        }
      }
    );
  }, [requestCategory]);

  useEffect(() => {
    if (!requestCategorySlug) return;

    fetchCategory(requestCategorySlug).then(
      (res: TApiResult & TApiCategoryResult) => {
        if (!res.isSuccess || !res.result)
          return history.push(routes.error.path);
        const newTitle = `${res.result.name}${
          "一覧"
        }`;
        if (
          contentTypeIds.length === 1 &&
          contentTypeIds[0] === CONTENT_TYPE.video
        ) {
          setTitle("動画一覧");
        } else {
          setTitle(newTitle);
        }
      }
    );
  }, [requestCategorySlug]);

  useEffect(() => {
    const offset = (requestPage - 1) * limitCount;
    // 検索時には新着を含めて検索する、またカテゴリから一覧を表示する際も新着を含める。
    // 空文字で検索することがあるので、undefinedか否かで判定する
    if (requestTitle !== undefined || requestCategory || requestCategorySlug) {
      contentTypeIds.push(CONTENT_TYPE.news);
    }
    fetchContents(
      offset,
      pager.limit,
      contentTypeIds,
      requestCategory,
      requestCategorySlug,
      requestTitle,
      undefined,
      undefined,
      requestCategory ? true : undefined
    ).then((res: TApiResult & TApiContentsResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newContents: contentSummary[] =
        res.result.contents.map(mapContentToSummary);
      setNotFound(!Boolean(res.result.total_count));
      setTotalContentsCount(res.result.total_count);
      const newPager: TPager = new TPager(
        requestPage,
        res.result.total_count,
        limitCount
      );
      setContents(newContents);
      setDisplayedContents(newContents.slice(0, limitCount));
      setPager(newPager);
      if (!requestCategory && !requestCategorySlug) {
        if (requestTitle) {
          setTitle(
            `${requestTitle}${"一覧"}`
          );
          return;
        }

        if (contentTypeIds.length === 1) {
          if (contentTypeIds[0] === CONTENT_TYPE.video) {
            setTitle("動画一覧");
            return;
          }
          if (contentTypeIds[0] === CONTENT_TYPE.news) {
            setTitle("お知らせ一覧");
            return;
          }
        }

        if (contentTypeIds.length === 2) {
          if (
            contentTypeIds.includes(CONTENT_TYPE.information) &&
            contentTypeIds.includes(CONTENT_TYPE.video)
          ) {
            setTitle("コンテンツ一覧");
            return;
          }
        }
        setTitle(`${"お役立ち記事"}一覧`);
      }
    });
  }, [requestPage, requestCategory, requestTitle, requestCategorySlug, spFlag]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const loadMore = (entries: IntersectionObserverEntry[]) => {
    if (
      entries[0].isIntersecting &&
      nextIndex < totalContentsCount &&
      !isLoading
    ) {
      setIsLoading(true);

      timerRef.current = setTimeout(() => {
        const newPage = currentPage + 1;
        const offset = (newPage - 1) * limitCount;
        if (
          requestTitle !== undefined ||
          requestCategory ||
          requestCategorySlug
        ) {
          contentTypeIds.push(CONTENT_TYPE.news);
        }

        fetchContents(
          offset,
          limitCount,
          contentTypeIds,
          requestCategory,
          requestCategorySlug,
          requestTitle,
          undefined,
          undefined,
          requestCategory ? true : undefined
        ).then((res: TApiResult & TApiContentsResult) => {
          if (!res.isSuccess || !res.result)
            return history.push(routes.error.path);
          const newContents: contentSummary[] =
            res.result.contents.map(mapContentToSummary);
          setContents((prevContents) => [...prevContents, ...newContents]);
          setDisplayedContents((prevContents) => [
            ...prevContents,
            ...newContents,
          ]);
          setCurrentPage(newPage);
          setNextIndex((prevIndex) => prevIndex + limitCount);
          setIsLoading(false);

          if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
          }
        });
      }, 500);
    }
  };

  const observer = new IntersectionObserver(loadMore, {
    rootMargin: "100px",
  });

  useEffect(() => {
    const target = document.getElementById("scrollTarget");
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [
    totalContentsCount,
    requestTitle,
    requestCategory,
    requestCategorySlug,
    contentTypeIds,
    history,
    currentPage,
    observer,
  ]);

  const renderContents = spFlag ? displayedContents : contents;
  const isNews = renderContents.every((content) => content.contentTypeId === 1);
  const isVideos = renderContents.every(
    (content) => content.contentTypeId === 5
  );

  const getUrlPath = (): string => {
    if (requestCategory)
      return `${routes.informations.path}?category=${requestCategory}`;
    if (requestCategorySlug)
      return `${routes.informations.path}?category_slug=${requestCategorySlug}`;
    if (requestTitle)
      return `${routes.informations.path}?title=${requestTitle}`;
    if (isNews) return routes.news.path;
    if (isVideos) return routes.videos.path;
    return routes.informations.path;
  };

  return (
    <section
      className={
          STInfoArticleList.info_article_list_article_list_new
      }
    >
      <h1
        className={`${STInfoArticleList.info_article_list_article_list_title} ${PAGE_CONFIG.STATIC_CLASSES.SUBJECT}`}
      >
        {title}
      </h1>
      {notFound && (
        <p
          className={STInfoArticleList.info_article_list_article_list_notfound}
        >
          該当する結果が存在しませんでした。
        </p>
      )}
      {contents.length !== 0 && (
        <>
          <div
            className={STInfoArticleList.info_article_list_article_list_inner}
          >
            {renderContents.map((content) => {
              const CommonCardProps = {
                key: content.id,
                title: content.title,
                contentTypeId: content.contentTypeId,
                className: STInfoArticleCardList.info_article_card_list_article,
                linkUrl: content.linkUrl,
                thumbnailUrl: content.imageUrl ? content.imageUrl : "",
                thumbnailAlt: content.title,
                content: content.content,
                createdAt: content.createdAt,
                goodCount: content.goodCount,
              };
              if (isNews) {
                return spFlag ? (
                  <SpNewsCard {...CommonCardProps} />
                ) : (
                  <NewsCard {...CommonCardProps} />
                );
              }

              return spFlag ? (
                <SpContentCard {...CommonCardProps} />
              ) : (
                <ArticleCard {...CommonCardProps} />
              );
            })}
          </div>
          {!spFlag && (
            <Pagenation
              className=""
              urlPath={getUrlPath()}
              total={pager.total}
              start={1}
              end={pager.max}
              current={pager.current || 1}
            />
          )}
        </>
      )}
      {spFlag && <div id="scrollTarget" style={{ height: "1px" }}></div>}
    </section>
  );
};

export default InfoArticleList;
