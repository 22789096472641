import {
  createContext,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PAGE_CONFIG from "../config/page";
import { routes } from "../router/Router";
import TApiResult from "../types/api/TApiResult";
import { fetchChatConfig } from "../utils/api/fetchChatConfig";
import { fetchSite, TApiSiteResult } from "../utils/api/fetchSite";
import { fetchSitePreview } from "../utils/api/fetchSitePreview";
import setManifestURL from "../utils/generageManifest";
import getImageUrl from "../utils/getImageUrl";
import { getSubdomain } from "../utils/getSubdomain";

export type SiteContextType = {
  name: string;
  isPoint: boolean;
  isPublic: boolean;
  tenantId: string;
  googleAnalyticsId?: string;
  enablesHomePopup: boolean;
  homePopupDescription?: string;
  backgroundColor: string;
  mainColor: string;
  backgroundPC: string;
  backgroundSP: string;
  logo: string;
  favicoPC: string;
  favicoIOS: string;
  isChat: boolean;
  sendbirdAppId: string;
  groupChatTextHeader: string;
  email: string;
  siteAllowChatMailSending: boolean;
  contractCompanyId: number;
  appName: string;
  installPrompt: any;
  setInstallPrompt: Function;
  uuid: string;
  homePupopUpdatedAt: string;
};

export const SiteContext = createContext({} as SiteContextType);

export const SiteProvider = (props: { children: ReactNode }) => {
  const history = useHistory();
  const { children } = props;
  const [tenantId, setTenantId] = useState<string>();
  const [isPoint, setIsPoint] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const subdomain = getSubdomain(window.location.hostname);
  const [name, setName] = useState<string>("");
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState<string>();
  const [enablesHomePopup, setEnablesHomePopup] = useState<boolean>(false);
  const [homePupopUpdatedAt, setHomePupopUpdated] = useState<string>('');
  const [homePopupDescription, setHomePopupDescription] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>('');
  const [mainColor, setMainColor] = useState<string>('');
  const [backgroundPC, setBackgroundPC] = useState<string>('');
  const [backgroundSP, setBackgroundSP] = useState<string>('');
  const [logo, setLogo] = useState<string>('');
  const [favicoPC, setFaviconPC] = useState<string>('');
  const [favicoIOS, setFaviconIOS] = useState<string>('');
  const [isChat , setIsChat] = useState<boolean>(false);
  const [sendbirdAppId, setSendbirdAppId] = useState<string>('');
  const [groupChatTextHeader, setGroupChatTextHeader] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [siteAllowChatMailSending, setSiteAllowChatMailSending] = useState<boolean>(false);
  const [contractCompanyId, setContractCompanyId] = useState<number>(0);
  const [appName, setAppName] = useState<string>('');
  const [installPrompt, setInstallPrompt] = useState<any>(null);
  const [cookies, setCookie] = useCookies(["ambLpView"]);
  const [uuid, setUuid] = useState(cookies.ambLpView ? cookies.ambLpView : "");
  const key = window.location.search.split('=')[1]?.startsWith('site') ? window.location.search.split('=')[1] : '';
  const HeadForFavicons = () => (
    <Helmet>
      <link
        rel="icon"
        href={!key ? 
          getImageUrl(
            subdomain,
            PAGE_CONFIG.IMAGE_URL_PATH.SITE,
            PAGE_CONFIG.FAVICON
          )
          :
          favicoPC ?? ''
        }
      />
      <link
        rel="apple-touch-icon"
        href={!key ?
          getImageUrl(
            subdomain,
            PAGE_CONFIG.IMAGE_URL_PATH.SITE,
            PAGE_CONFIG.APPLE_TOUCH_ICON
          )
          :
          favicoIOS ?? ''
        }
      />
    </Helmet>
  );

  useEffect(() => {
    const handleData = async () => {
      if(key){
        await fetchSitePreview(key).then((res : any) => {
          if (!res.isSuccess || !res.result) return history.push(routes.error.path);
          setBackgroundColor(res.result?.sub_color);
          setMainColor(res.result?.main_color);
          setBackgroundPC(res.result?.background_pc?.length > 300 ? `data:image/png;base64,${res.result.background_pc}` : res.result?.background_pc);
          setBackgroundSP(res.result?.background_sp?.length > 300 ? `data:image/png;base64,${res.result.background_sp}` : res.result?.background_sp);
          setLogo(res.result?.logo?.length > 300 ? `data:image/png;base64,${res.result.logo}` : res.result?.logo);
          setFaviconPC(res.result?.favico_pc?.length > 300 ? `data:image/png;base64,${res.result.favico_pc}` : res.result?.favico_pc);
          setFaviconIOS(res.result?.favico_sp?.length > 300 ? `data:image/png;base64,${res.result.favico_ios}` : res.result?.favico_ios);
          document.documentElement.style.setProperty("--main-color", res.result.main_color);
          document.documentElement.style.setProperty("--sub-color", res.result.sub_color);
        })
      }
      fetchSite().then((res: TApiResult & TApiSiteResult) => {
        if (!res.isSuccess || !res.result) return;
        setName(res.result.name);
        setTenantId(res.result.gcp_id);
        setManifestURL(res.result.app_name, subdomain, res.result.name);
        setIsPoint(res.result.is_point === 1);
        setIsPublic(res.result.is_public);
        setGoogleAnalyticsId(res.result.google_analytics_id);
        setEnablesHomePopup(res.result.enables_home_popup);
        setEmail(res.result.email);
        setSiteAllowChatMailSending(res.result.allow_chat_mail_sending);
        setContractCompanyId(res.result.contract_company_id);
        setAppName(res.result.app_name);
        setHomePupopUpdated(res.result.home_popup_updated_at);
        if(!key){
          setBackgroundColor(res.result.sub_color);
          setMainColor(res.result.main_color);
          document.documentElement.style.setProperty("--main-color", res.result.main_color);
          document.documentElement.style.setProperty("--sub-color", res.result.sub_color);
        }
        const defaultDescription =
          "ホーム画面への追加はお済みですか？ \n簡単10秒設定で↓↓↓  \nさらに便利に活用いただけます！";
        const homePopupDescription =
          res.result.home_popup_description ?? defaultDescription;
        setHomePopupDescription(homePopupDescription);
        const handleBeforeInstallPrompt = (event: any) => {
          event.preventDefault();
          setInstallPrompt(event);
        };
        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        return () => {
          window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
      });
    }
    handleData();
  }, [subdomain, tenantId]);

  useEffect(() => {
    fetchChatConfig().then((res: any) => {
      if (!res.isSuccess || !res.result) return;
      setIsChat(res.result[0].is_active);
      setSendbirdAppId(res.result[0].sendbird_application_id);
      setGroupChatTextHeader(res.result[0].group_chat_text_header);
    });
    const date = new Date();
    const str = date
      .toISOString()
      .replace(/[^0-9]/g, "");
    const chars = "abcdefghijklmnopqrstuvwxyz";
    let rdmStr = "";
    for (var i = 0; i < 8; i++) {
      rdmStr += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    if (!uuid) {
      setCookie("ambLpView", `${str}-${rdmStr}`, {
        maxAge: 60 * 60 * 24 * 400,
        path: "/",
      });
      setUuid(`${str}-${rdmStr}`);
    } else {
      setCookie("ambLpView", uuid, { maxAge: 60 * 60 * 24 * 400, path: "/" });
    }
  }, []);

  if (subdomain === "") return <>subdomain is empty</>;
  if (!tenantId)
    return (
      <>
        <HeadForFavicons />
      </>
    );

  return (
    <SiteContext.Provider
      value={{
        name,
        isPoint,
        isPublic,
        tenantId,
        googleAnalyticsId,
        enablesHomePopup,
        homePupopUpdatedAt,
        homePopupDescription,
        backgroundColor,
        mainColor,
        backgroundPC,
        backgroundSP,
        logo,
        favicoPC,
        favicoIOS,
        isChat,
        sendbirdAppId,
        groupChatTextHeader,
        email,
        siteAllowChatMailSending,
        appName,
        contractCompanyId,
        installPrompt,
        setInstallPrompt,
        uuid
      }}
    >
        <HeadForFavicons />
        {children}
    </SiteContext.Provider>
  );
};